.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 1.5em 0em 0.5em 0em;
}
.modal-footer input {
  margin-right: 0.5em;
}

#TableConteudoProgLancDiario {
  border-collapse: collapse;
  border: 1px solid rgba(133, 133, 133, 0.5176470588);
  margin: 1em 0em 0em 0em;
  width: 100%;
}
#TableConteudoProgLancDiario th {
  border: 1px solid rgba(133, 133, 133, 0.5176470588);
}
#TableConteudoProgLancDiario td {
  border: 1px solid rgba(133, 133, 133, 0.5176470588);
  padding: 0.5em;
}

#confirmar {
  background-color: #2d78a0;
  border: 1px solid #2d78a0;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
}

#confirmar:hover {
  background-color: #3b89b4;
  border: 1px solid #3b89b4;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
  cursor: pointer;
}

#cancelar {
  background-color: #828282;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
}

#cancelar:hover {
  background-color: #adadad;
  border: 1px solid #adadad;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
  cursor: pointer;
}/*# sourceMappingURL=ModalConteudoProg.css.map */