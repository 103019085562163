.GerenciarGeneralAdminContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100vw;
    height: 100vh;

    .GerenciarPageRigthSide {
        display: flex;
        flex: 1;
        flex-direction: column;

        #GerenciarTitlePage {
            display: flex;
            font-size: 10px;
            color: #534A3C;
            font-weight: bold;
            opacity: 0.6;
            letter-spacing: 5px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 50px;
        }

        .GerenciarBodyPainelContainer {
            display: flex;
            flex: 1;
            align-self: center;
            justify-self: center;
            justify-content: space-around;
            align-items: center;
            width: 80%;
            max-width: 60em;
            margin-bottom: 5em;
            border-radius: 20px;
            border-top: 40px solid #324354;
            background-color: white;
            box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 


            .CardGerenciar {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 12em;
                height: 15em;
                border-radius: 10px;
                border: 4px solid #32435431;
                text-decoration: none;
                color: #324354;
                font-weight: bold;

                div {
                    opacity: 0.7;
                }

                .IconGerenciarPainel {
                    font-size: 80px;
                    opacity: 0.7;
                    color: #324354;
                }    
            }

            .CardGerenciar:hover {
                cursor: pointer;
                border: 4px solid #324354db;
                box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 
                transition: 0.5s;
                
                .IconGerenciarPainel {
                    transition: 0.5s;
                    opacity: 1;
                }

                div {
                    opacity: 1;
                    transition: 0.5s;
                }
            }
        }
    }

}