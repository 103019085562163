body {
    margin: 0;
    padding: 0;
    // font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    // background-color: #eaeaea;

    .SideBar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 250px;
        background-color: #2F4050;
        background-image: url('../../images/Edu-4.png');
        background-blend-mode: luminosity;
        background-size: cover;
        overflow: hidden;
        margin-right: 0px;
    
        header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
            margin-bottom: 20px;
            color: white;
            
            #Escola {
                font-weight: bold;
                margin: 0.6em 0em 0em 0em;
                text-transform: capitalize;
                max-width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
            }
            
            #Cargo {
                font-weight: lighter;
                font-size: 12px;
                opacity: .7;
            }
            
            hr {
                margin-top: 10px;
                margin-bottom: -10px;
                width: 65%;
                opacity: 0.4;
            }
        }
    
        .SideBarList {
            // height: 100vh;
            padding: 0;
            width: 100%;
            overflow: hidden;
            
            .row {
                width: 100%;
                height: 50px;
                list-style-type: none;
                margin: 0%;
                display: flex;
                flex-direction: row;
                color: white;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 12px;
            }

            .row:hover {
                cursor: pointer;
                background-color: #1f2933b9;
                border-left: 4px solid #4BB4FF;
                transition: .3s;
            }

            #active {
                background-color: #1020318e;
            }

            #icon {
                flex: 30%;
                display: grid;
                place-content: center;
            }

            #title {
                flex: 70%;
            }
        }
    
        #SairButtonSideBarAdmin {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 110px;
            background-color: red;
            // margin-top: -310px;
            background: none;
            border: none;
            color: #fafafa;
            outline: none;
            font-weight: bold;
            margin-left: 10px;
            padding: 2em 20px;
            font-size: 12px;
            cursor: pointer;
        }    
    }
}