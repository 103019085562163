.NotasTabelaGeralContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1em 3em;
    text-align: center;
    border: 1px solid #70707093;
    max-height: min-content;
    overflow-x: hidden;

    #srollInvisibleHeadItem {
        width: 6px;
    }

    .NotastabelaGeralBodyDataContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;

        :nth-child(2){
            display: flex;
            // background-color: yellow;
            min-width: 50%;
        }

        #NotasTabelaGeralDashRow {
            display: flex;
            flex: 1;
            flex-direction: row;
            max-height: 30px;

            #itemBodyNotasTabelaGeralDashRow {
                display: flex;
                flex: 1;
                padding: 0.5em;
                border: 1px solid #c5c5c57e;
                border-collapse: collapse;
                justify-content: center;
            }
        }

        #NotasTabelaGeralDashRow:hover {
            background-color: #e0e0e07e;
        }


    }

    :nth-child(2){
        display: flex;
        // background-color: yellow;
        min-width: 50%;
    }


    .NotasTabelaGeralTable {
        display: flex;
        width: 100%;
        height: fit-content;
        border-collapse: collapse;

        .NotasTabelaGeralHeaderTable {
            display: flex;
            flex: 1;
            flex-direction: row;

            #itemHeaderNotasTabelaGeralDashRow {
                display: flex;
                flex: 1;
                padding: 0.5em;
                // border: 1px solid #c5c5c57e;
                border-collapse: collapse;
                justify-content: center;
            }

            div {
                background: #f3f3f3;
                color: #333333a1;
                padding: 0.5em;
                font-weight: bold;
                border-bottom: 1px solid #d3d3d3;
                position: sticky;
                top: 0;
            }
        }

    }
    
    .NotasDadosButtonsContainer {
        margin-top: -15px;
        background-color: red;
    }

}