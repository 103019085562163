.NotasTabelaGeralContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1em 3em;
    text-align: center;
    border: 1px solid #70707093;
    max-height: min-content;
    overflow-x: hidden;

    #srollInvisibleHeadItem {
        width: 6px;
    }

    .NotastabelaGeralBodyDataContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;

        :nth-child(2){
            display: flex;
            // background-color: yellow;
            min-width: 50%;
        }

        #NotasTabelaGeralDashRow {
            display: flex;
            flex: 1;
            flex-direction: row;
            max-height: 36px;

            #itemBodyNotasTabelaGeralDashRow {
                display: flex;
                flex: 1;
                padding: 0.5em;
                border: 1px solid #c5c5c57e;
                border-collapse: collapse;
                justify-content: center;
            }

            #visualizarQualitativasTabelaButton {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                border: none;
                color: #324354;
                background-color: transparent;
                border-collapse: collapse;
                border-radius: 5px;
                font-weight: bold;
                font-size: 12px;

                #iconSearch {
                    color: #324354;
                    margin-right: 2px;
                }
            }

            #visualizarQualitativasTabelaButton:hover {
                cursor: pointer
            }
        }

        #NotasTabelaGeralDashRow:hover {
            background-color: #e0e0e07e;
        }


    }

    :nth-child(2){
        display: flex;
        // background-color: yellow;
        min-width: 50%;
    }

    .NotasTabelaGeralTable {
        display: flex;
        width: 100%;
        height: fit-content;
        border-collapse: collapse;

        .NotasTabelaGeralHeaderTable {
            display: flex;
            flex: 1;
            flex-direction: row;

            #itemHeaderNotasTabelaGeralDashRow {
                display: flex;
                flex: 1;
                padding: 0.5em;
                // border: 1px solid #c5c5c57e;
                border-collapse: collapse;
                justify-content: center;
            }

            div {
                background: #f3f3f3;
                color: #333333a1;
                padding: 0.5em;
                font-weight: bold;
                border-bottom: 1px solid #d3d3d3;
                position: sticky;
                top: 0;
            }
        }

    }
    
    .NotasDadosButtonsContainer {
        margin-top: -15px;
        background-color: red;
    }   
}

#TitleModalNotasQuaAlunoQuadro {
    color: #324354;
    // opacity: 0.8;
}

#qualitativasTabelaAlunosProgress{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2;
}

#notasQuaAlunoGrade {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: fit-content;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    scrollbar-width: thin;  
    
    #notasQuaAlunoGradeItemHeader{
        display: flex;
        padding: 0.5em 1.2em;
        

        #notasQuaAlunoGradeItemDescricaoHeader {
            display: flex;
            flex: 2;
            font-weight: bold;
            opacity: 0.8;
            font-size: 18px;
        }

        #notasQuaAlunoGradeItemConceitoHeader {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            max-width: 6.5em;
            border-radius: 5px;
            font-weight: bold;
            opacity: 0.8;
            font-size: 18px;
        }
    }

    #notasQuaAlunoGradeItem {
        display: flex;
        padding: 0.5em 1.2em;

        #notasQuaAlunoGradeItemDescricao {
            display: flex;
            flex: 2;
        }

        #notasQuaAlunoGradeItemConceito {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            max-width: 6em;
            background-color: #32435422;
            border-radius: 5px;
            
        }
    }

    #notasQuaAlunoGradeItem:nth-child(even){
        background-color: rgba(0, 0, 0, 0.04);
    }
}