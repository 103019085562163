.TabelaGeralContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 3em;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.5764705882);
  max-height: -moz-min-content;
  max-height: min-content;
  overflow-x: hidden;
}
.TabelaGeralContainer #srollInvisibleHeadItem {
  width: 6px;
}
.TabelaGeralContainer .tabelaGeralBodyDataContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}
.TabelaGeralContainer .tabelaGeralBodyDataContainer #TabelaGeralDashRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 30px;
}
.TabelaGeralContainer .tabelaGeralBodyDataContainer #TabelaGeralDashRow #itemBodyTabelaGeralDashRow {
  display: flex;
  flex: 1;
  padding: 0.5em;
  border: 1px solid rgba(197, 197, 197, 0.4941176471);
  border-collapse: collapse;
  justify-content: center;
}
.TabelaGeralContainer .tabelaGeralBodyDataContainer #TabelaGeralDashRow:hover {
  background-color: rgba(224, 224, 224, 0.4941176471);
}
.TabelaGeralContainer table {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-collapse: collapse;
}
.TabelaGeralContainer table thead th {
  background: #f3f3f3;
  color: rgba(51, 51, 51, 0.631372549);
  padding: 0.5em;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d3;
  position: sticky;
  top: 0;
}/*# sourceMappingURL=TabelaGeral.css.map */