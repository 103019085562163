body #transferirFaltasDivisorLine {
  margin: 0.2em 0;
  width: 100%;
}
body #transferirFaltasDivisorLine hr {
  margin-top: 0;
}

#TransferirFaltasGeneralContainer {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}
#TransferirFaltasGeneralContainer #TransferirFaltasContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
  align-items: center;
  height: 30em;
  width: 80%;
  max-width: 80em;
  margin-bottom: 5em;
  border-radius: 20px;
  border-top: 40px solid #324354;
  background-color: white;
  box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer label {
  display: flex;
  flex: 1 1;
  color: #313131;
  opacity: 1;
  font-size: small;
  text-transform: uppercase;
  align-self: flex-start;
  justify-self: flex-start;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer label.todasAsNotasDisabled {
  font-weight: normal;
  opacity: 0.5;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer select {
  display: flex;
  flex: 1 1;
  width: 100%;
  padding: 0.8em;
  border-radius: 8px;
  border: 1px solid #313131;
  margin-bottom: 1em;
  margin-top: 0.2em;
  color: rgba(49, 49, 49, 0.8352941176);
  max-height: 3em;
  outline: none;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 60%;
  max-width: 450px;
  justify-content: center;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasTodasContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasTodasContainer label {
  font-size: medium;
  margin-left: 0.2em;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasTodasContainer:hover input[type=checkbox],
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasTodasContainer:hover label {
  opacity: 0.9;
  transition: 0.2s;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer hr {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 100%;
  opacity: 0.8;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasSelectOptionsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasSelectOptionsContainer #TransferirFaltasRow1Container {
  display: flex;
  flex-direction: row;
  gap: 2em;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasSelectOptionsContainer #TransferirFaltasRow1Container #TransferirFaltasCursoRowContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasSelectOptionsContainer #TransferirFaltasRow1Container #TransferirFaltasTurmaRowContainer {
  display: flex;
  flex-direction: column;
  min-width: 5em;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer #TransferirFaltasButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 4em;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer input[type=checkbox] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 0.2em;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #TransferirFaltasOptionsContainer input[type=submit] {
  background-color: #324354;
  width: 10em;
  padding: 0.7em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
#TransferirFaltasGeneralContainer .TransferirFaltasPainelContainer #avaliacao {
  border: 1px solid #313131;
  opacity: 1;
  color: #324354;
}
#TransferirFaltasGeneralContainer #TransferirFaltasTitlePage {
  display: flex;
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}/*# sourceMappingURL=TransferirFaltas.css.map */