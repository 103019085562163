.DiarioTabelaDashContainer {
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable {
  display: flex;
  justify-content: center;
  max-height: 92%;
  overflow-y: scroll;
  cursor: default;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table {
  display: table;
  width: 90%;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table thead tr, .DiarioTabelaDashContainer .DiarioTabelaDashTable table th {
  padding: 8px 2px 8px 10px;
  color: rgba(51, 51, 51, 0.631372549);
  text-align: left;
  font-size: 14px;
  background-color: #fafafa;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table thead th {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody {
  overflow: scroll;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody tr td {
  padding: 8px 2px 8px 10px;
  max-height: 10px;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody tr {
  max-height: 10px;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody tr:hover {
  background-color: #ececec;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody tr:nth-child(even) {
  background-color: rgba(189, 200, 220, 0.1882352941);
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable table tbody tr:nth-child(even):hover {
  background-color: #e4e4e4;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable #ContainerIconSearchLoadingStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.8;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable #ContainerIconSearchLoadingStatus img#IdIconSearchLoadingStatus {
  width: 130px;
  height: 130px;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable #DiarioTabelaDashRow:hover {
  background-color: #ececec;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable #IconEditProfessor {
  cursor: pointer;
  color: #324354;
  margin: 2px;
}
.DiarioTabelaDashContainer .DiarioTabelaDashTable #IconViewDiario {
  cursor: pointer;
}/*# sourceMappingURL=DiarioTabela.css.map */