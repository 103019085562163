.modal-content-notas-aux {
  display: flex;
  flex-direction: column;
  min-width: 40em;
  max-width: 60em;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 70vh;
  text-transform: uppercase;
  cursor: default;
}
.modal-content-notas-aux h2 {
  text-align: center;
  color: #313131;
  opacity: 0.9;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-content-notas-aux p {
  font-size: 14px;
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -12px 0px 23px 0px;
}
.modal-content-notas-aux #ButtonModalStudentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.modal-content-notas-aux #ButtonConfirmDash {
  padding: 0.8em 1.5em;
  margin-right: 1.6em;
}

.NotasDadosContainer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.NotasDadosContainer .ReactModal__Content {
  background-color: white;
}
.NotasDadosContainer .ReactModal__Content--after-open {
  background-color: white;
}
.NotasDadosContainer .NotasDadosDashContainer {
  display: flex;
}
.NotasDadosContainer #buttonAuxNotasAdmin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #324354;
  color: white;
  border: 1px solid #324354;
  border-radius: 5px;
  padding: 0.6em 2em;
  max-width: 9em;
  margin-top: 5px;
}
.NotasDadosContainer .NotasDadosDashBarraPesquisa {
  display: flex;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.NotasDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.NotasDadosContainer input {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(186, 186, 186, 0.8705882353);
  max-width: -moz-fit-content;
  max-width: fit-content;
  outline: none;
}
.NotasDadosContainer input:hover {
  border-bottom: 1px solid #324354;
}
.NotasDadosContainer input:focus {
  border-bottom: 1px solid #324354;
  border-color: #324354;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 70px;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosIDContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosIDContainer #InputCodigo {
  background-color: rgba(49, 49, 49, 0.1921568627);
  border-radius: 10px;
  padding: 2px 10px;
  text-align: center;
  color: rgba(49, 49, 49, 0.7294117647);
  margin: 1em 0em 0em 0em;
  margin-right: -15px;
  border: none;
  width: 90px;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosCursoContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosCursoContainer #NotasDadosCursoContainerEdit {
  display: flex;
  flex-direction: row;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosCursoContainer #InputCurso {
  display: flex;
  flex: 1;
  margin: 1em 0em 0em 0em;
  min-width: 500px;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosTurmaContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 3em 2em 0em;
}
.NotasDadosContainer .NotasDadosBasicInfoContainer .NotasDadosTurmaContainer #InputTurma {
  margin: 1em 0em 0em 0em;
  width: 40px;
}
.NotasDadosContainer .NotasDadosBasicInfoContainerRow2 {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  max-height: 80px;
}
.NotasDadosContainer .NotasDadosBasicInfoContainerRow2 .QualitativasDadosAvaliacaoContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 3em;
}
.NotasDadosContainer .NotasDadosBasicInfoContainerRow2 .QualitativasDadosAvaliacaoContainer #InputAvaliacao {
  margin: 1em 0em 0em 0em;
  width: 100px;
}
.NotasDadosContainer .PermissaoDivDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: -5px;
  font-weight: bold;
  opacity: 0.5;
}
.NotasDadosContainer .NotasDadosSenhaContainer {
  display: flex;
  flex-direction: column;
  width: -moz-min-content;
  width: min-content;
  margin: 1em 0em 0em 2.5em;
}
.NotasDadosContainer .NotasDadosSenhaContainer .SenhaInfoContainer {
  display: flex;
  flex-direction: row;
}
.NotasDadosContainer .NotasDadosSenhaContainer .SenhaInfoContainer #EditIconSenha:hover {
  color: #324354;
  cursor: pointer;
}
.NotasDadosContainer .NotasDadosSenhaContainer .InputSenhaEditarSenha {
  margin: 1em 0em 0em 0em;
  width: -moz-min-content;
  width: min-content;
  cursor: pointer;
}
.NotasDadosContainer .NotasDadosSenhaContainer .InputSenhaEditarSenha:disabled {
  cursor: default;
  outline: none;
}
.NotasDadosContainer .NotasDadosSenhaContainer .InputSenhaEditarSenha:hover {
  border-bottom: 1px solid #324354;
  transition: 0.4s;
}
.NotasDadosContainer .NotasDadosSenhaContainer .InputSenhaEditarSenha:hover:disabled {
  cursor: default;
  outline: none;
}
.NotasDadosContainer .NotasDadosSenhaContainer .InputSenhaEditarSenha:focus {
  outline: none;
  border-bottom: 1px solid #324354;
  transition: 0.4s ease-out;
}
.NotasDadosContainer .disabledBtnEdit {
  cursor: crosshair;
}
.NotasDadosContainer #TextPermissoesNotasDados {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;
}
.NotasDadosContainer .NotasDadosButtonsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-left: 2em;
  margin-right: 3em;
  justify-content: space-between;
  align-items: center;
}
.NotasDadosContainer .linkBtn {
  text-decoration: none;
}

#notasDadosTableContainer {
  display: flex;
  justify-content: center;
}
#notasDadosTableContainer table {
  display: block;
  overflow: scroll;
  max-height: 20em;
  border-collapse: collapse;
  border: 1px solid rgba(50, 66, 83, 0.5);
  margin: 0;
  padding: 0;
}
#notasDadosTableContainer table td {
  padding: 0.2em 0.7em;
}
#notasDadosTableContainer table thead {
  position: sticky;
  top: 0;
  font-weight: bold;
  text-align: center;
  background-color: rgb(50, 66, 83);
  color: white;
  margin: 0;
  padding: 0;
}
#notasDadosTableContainer table tbody {
  font-size: 14px;
}
#notasDadosTableContainer table tbody tr:nth-child(even) {
  background-color: rgba(122, 146, 171, 0.1);
}/*# sourceMappingURL=QualitativasDados.css.map */