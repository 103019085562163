@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    font-family: 'Poppins', arial;
    background-color: #fafafa;
    margin: 0;
    padding: 0;

    .container {
        display: flex;
        flex: 1;
        margin: 0;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        header {
            background-color: #368EBD;
            min-height: 25vh;
            width: 100%;
            margin: 0px;
    
            .header-infodat-label{
                color: white;
                margin: -15vh 0em 0em .5vw;
            }
        }
    
        .icon-svg {
            margin-top: -2em;
        }
        .loading {
            position: absolute;
        }

        #PainelAdministrativoLink {
            display: flex;
            color: #3080ac;
            width: 85%;
            padding-left: 5px;
            margin: 12px 0px 4px 0px ;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 12px;
            letter-spacing: normal;

            a:hover {
                color: #3693c5;
                text-decoration: none;
            }
        }
        
        .professor-form-card{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 2em;
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: 1em;
            margin-top: -7vh;
            min-width: 15em;
            max-width: 19em;
            
            h1 {
                color: #333333;
            }            
            
            select {
                color: #333333;
                margin-bottom: 5px;
                width: 85%;
                font-size: 16px;
                background-color: #fafafa;
                padding: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                outline: none;
                

                // option {
                //     padding: 10px;
                //     margin: 5px
                // }


                option {
                    margin: 0px;
                    padding: 5px;
                    font-size: 16px;
                    color: #333333;
                    background-color: white;   
                }

                option:nth-child(even){
                    background-color: #c4e5f73d;
                }

                #escolaOption {
                    color: #bababa;
                }
                
                #escolaOption:focus {
                    color: red;
                }
            }

            select#professores:focus {                 
                div.option-Professores{
                    height: 30px;
                }
                option {
                    margin: 0px;
                    padding: 5px;
                    font-size: 16px;
                    color: #333333;
                    background-color: white;   
                }

                option:nth-child(even){
                    background-color: #c4e5f73d;
                }
            }
    
            select:focus{
                outline: none !important;
                border:1px solid #368EBD;
                box-shadow: 0 0 10px #aae7ff75;
            }
    
            input.password {
                width: 80%;
                font-size: 16px;
                padding: 5px;
                border: 1px solid rgba(0, 0, 0, 0.3);
            }
    
            input.password:focus{
                outline: none !important;
                border:1px solid #3080ac;
                box-shadow: 0 0 10px #aae4ff9a;
                padding: 5px;
                color: #1b85be;
            }   
    
            input.button {
                width: 100%;
                height: 100%;
                padding: 10px;
                background-image: linear-gradient(#36bd41,#23832b);
                background-color:#36bd41;
                color: white;
                font-size: 15px;
                font-weight: bold;
                border-width: 0px;
                border-radius: .5em;
                cursor: pointer;
            }
    
            input.button:hover {
                box-shadow: 0 0 10px #7fff8ace;
                background-image: linear-gradient(#3ed44a,#1d9127);
                background-color:#8be4928f;
                transition: 0.5s ease;
            }
    
            input.button:focus {
                outline: none !important;
            }
    
            Link {
                display: flex;
                justify-content: center;
                margin: auto;
                padding: auto;
                width: 10em;
                height: 2.4em;
                text-decoration: none;
                margin: .5em 0em 1.5em 0em;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 320px) {
        .container .professor-form-card {
            padding: 2.5em 1em;
            width: 85vw;
            margin-right: -.7em
        }
    }
}



