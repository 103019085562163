#ButtonDeleteDash {
    background-color: #b13b3b;
    border: 1px solid #b13b3b;
    width: max-content;
    height: max-content;
    margin: 1.5em 0em 1em 1.3em;
    padding: 0.6em 2em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 8px 5px -7px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205,205,205,0.63); 
}

#ButtonDeleteDash:hover {
    cursor: pointer;
    background-color: #c93c3c;
    border: 1px solid #c93c3c;
    transition: 0.2s;
}