#HomePageGeneralContainer {

    .ArredondarNotasPainelContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        justify-content: space-around;
        align-items: center;
        height: 30em;
        width: 80%;
        max-width: 80em;
        margin-bottom: 5em;
        border-radius: 20px;
        border-top: 40px solid #324354;
        background-color: white;
        box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);

        label {
            display: flex;
            flex: 1 1;
            color: #313131;
            opacity: 1;
            font-size: small;
            text-transform: uppercase;
            align-self: flex-start;
            justify-self: flex-start;
        }

        label.todasAsNotasDisabled {
            font-weight: normal;
            opacity: 0.5;
        }
        
        select {
            display: flex;
            flex: 1 1;
            width: 100%;
            padding: 0.8em;
            border-radius: 8px;
            border: 1px solid #313131;
            margin-bottom: 1em;
            margin-top: 0.2em;
            color: #313131d5;
            max-height: 3em;
            outline: none;
        }

        #ArredondarNotasOptionsContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 60%;
            max-width: 450px;
            justify-content: center;

            #ArredondarNotasTodasContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                // margin-top: 1em;

                label {
                    font-size: medium;
                    margin-left: 0.2em;
                    max-width: fit-content;
                }
            }

            #ArredondarNotasTodasContainer:hover {

                input[type="checkbox"],
                label {
                    opacity: 0.9;
                    transition: 0.2s;
                }
            }

            hr {
                margin-top: 1.5em;
                margin-bottom: 1.5em;
                width: 100%;
            }

            

            #ArredondarNotasSelectOptionsContainer {
                display: flex;
                flex: 1;
                flex-direction: column;

                #ArredondarNotasRow1Container {
                    display: flex;
                    flex-direction: row;
                    gap: 2em;

                    #ArredondarNotasCursoRowContainer {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                    }

                    #ArredondarNotasTurmaRowContainer {
                        display: flex;
                        flex-direction: column;
                        min-width: 5em;
                    }
                }
            }

            #ArredondarNotasButtonContainer {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                max-height: 4em;
            }


            input[type="checkbox"] {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-right: 0.2em;
            }

            input[type="submit"] {
                background-color: #324354;
                width: 10em;
                padding: 0.7em;
                border-radius: 5px;
                font-weight: bold;
                font-size: 14px;
                color: white;
                cursor: pointer;
            }

        }

        #avaliacao {
            border: 1px solid #313131;
            opacity: 1  ;
            // outline: #4a7fd5
            color: #324354;
        }
    }

    #ArredondarNotasTitlePage {
        display: flex;
        font-size: 10px;
        color: #534A3C;
        font-weight: bold;
        opacity: 0.6;
        letter-spacing: 5px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}