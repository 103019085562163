
#formRelatorio {
    display: flex;
    flex: 1;
    padding: 1em 8em 0em 8em;
    margin-bottom: -4em;
    margin-top: -1em;
    max-height: 18em;
    justify-content: center;
    
    #containerFormRelatorio {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 25em;
        justify-content: center;
        align-items: center;
        
        #cursoContainerFormRelatorio {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-right: 1em;
        }

        .containerFormFirstLine {
            display: flex;
            flex: 1;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;

            #curso {
                display: flex;
                flex: 2;
                min-width: 100%;
            }

            #turma {
                display: flex;
                flex: 1;
                min-width: 6em;
            }

            #alunos {
                display: none;
            }
        }

        label {
            display: flex;
            flex: 1;
            color: #313131;
            opacity: 0.5;
            font-size: small;
            // font-weight: normal;
            text-transform: uppercase;
            align-self: flex-start;
            justify-self: flex-start;
        }

        select {
            display: flex;
            flex: 1;
            width: 100%;
            padding: 0.8em;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 1em;
            margin-top: 0.2em;
            color: #313131d5;
        }

        #FiltrarEmBrancoContainer {
            display: flex;
            flex: 2;
            width: 100%;
            color: rgba(49, 49, 49, 0.5);
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            div:hover {
                cursor: pointer;
                color: rgba(49, 49, 49, 1);
                transition: 0.3s;
            }

            input {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-right: 0.2em;
            }
        }

        #CheckOptionsContainer {
            display: flex;
            flex: 1;
            width: 100%;
            justify-content: space-between;
        }

        #Avaliacao {
            margin-top: 0.8em;
        }

        #FiltrarCheckAvaliacaoContainer {
            display: flex;
            flex: 1;
            width: 100%;
            color: rgba(49, 49, 49, 0.5);
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.5em;
            text-transform: uppercase;

            div:hover {
                cursor: pointer;
                color: rgba(49, 49, 49, 1);
                transition: 0.3s;
            }

            input {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-right: 0.2em;
            }
        }
    }

    input[type=date] {
        max-width: 170px;
        border-radius: 5px;
        color: #313131;
        opacity: 0.7;
        font-size: x-large;
        text-decoration: none;
        outline: none;
        margin-bottom: 0.5em;
    }
}