.SistemaAvaliacaoCardContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 75%;
    overflow-y: scroll;
    border: 1px solid rgba(50, 67, 84, 0.1);

    .SistemaAvaliacaoCardContainerCard {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border: 3px solid rgba(50, 67, 84, 0.8);
        border-radius: 10px;
        padding: 1em;
        margin: 1em;
        height: 40em;
        // background-color: #fafafa;

        .SistemaAvaliacaoCardContainerCardTipoAv {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            max-height: 25px;
            margin-bottom: 0.5em;
            font-weight: bold;
            
        }

        .SistemaAvaliacaoCardDados {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: center;
            border: 2px solid rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            max-height: 30px;
            max-width: 90%;
            color: rgba(0, 0, 0, 0.3);
            padding: 0.2em 0.5em;
        }

        .SistemaAvaliacaoCardBody {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 1em;
            // border: 2px solid rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            color: rgba(0, 0, 0, 0.3);
            padding: 0em 0.5em;

            .SistemaAvaliacaoCardBodyAv {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 95%;
                margin: 0.4em;
                border: 2px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                color: rgba(0, 0, 0, 0.3);

                div {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;

                }
                .SistemaAvaliacaoCardBodyAvTitleAvaliacao {
                    color: rgba(50, 67, 84, 1);
                }

                .SistemaAvaliacaoCardBodyAvDateContainer {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    input {
                        margin-bottom: 2em;
                        border-radius: 10px;
                        font-size: 14px;
                        padding: 0.5em;
                        border: 1px solid rgba(50, 67, 84, 0.5);
                    }

                }
            }

        }
    }
}