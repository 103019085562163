.ProfessorDadosContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 4em;
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.ProfessorDadosContainer .ProfessorDadosInfoContainerGeral {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 130px;
}
.ProfessorDadosContainer .ProfessorDadosDashBarraPesquisa {
  display: flex;
  flex: 1;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  min-height: 28px;
  max-height: 28px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.ProfessorDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.ProfessorDadosContainer input {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(186, 186, 186, 0.8705882353);
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 70px;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosIDContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosIDContainer #InputId {
  background-color: rgba(49, 49, 49, 0.1921568627);
  border-radius: 10px;
  padding: 2px 8px;
  text-align: center;
  margin-right: -10px;
  color: rgba(49, 49, 49, 0.7294117647);
  margin: 1em 0em 0em 0em;
  border: none;
  width: 40px;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosNomeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosNomeContainer #InputNome {
  margin: 1em 0em 0em 0em;
  min-width: 600px;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosStatusContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 3em 2em 0em;
}
.ProfessorDadosContainer .ProfessorDadosBasicInfoContainer .ProfessorDadosStatusContainer #InputStatus {
  margin: 1em 0em 0em 0em;
  width: 40px;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer {
  display: flex;
  flex-direction: column;
  width: -moz-min-content;
  width: min-content;
  margin: 1em 0em 0em 2.5em;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .SenhaInfoContainer {
  display: flex;
  flex-direction: row;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .SenhaInfoContainer #EditIconSenha:hover {
  color: #324354;
  cursor: pointer;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .InputSenhaEditarSenha {
  margin: 1em 0em 0em 0em;
  width: -moz-min-content;
  width: min-content;
  cursor: pointer;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .InputSenhaEditarSenha:disabled {
  cursor: default;
  outline: none;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .InputSenhaEditarSenha:hover {
  border-bottom: 1px solid #324354;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .InputSenhaEditarSenha:hover:disabled {
  cursor: default;
  outline: none;
}
.ProfessorDadosContainer .ProfessorDadosSenhaContainer .InputSenhaEditarSenha:focus {
  outline: none;
}
.ProfessorDadosContainer .disabledBtnEdit {
  cursor: crosshair;
}
.ProfessorDadosContainer #TextPermissoesProfessorDados {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0.5em 0em;
  max-height: 20px;
}
.ProfessorDadosContainer .ProfessorDadosBodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-bottom: 1.2em;
}
.ProfessorDadosContainer .ProfessorDadosButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -1.5em;
  text-decoration: none;
}
.ProfessorDadosContainer .ProfessorDadosButtonsContainer #linkButtonBack {
  text-decoration: none;
}/*# sourceMappingURL=ProfessorDados.css.map */