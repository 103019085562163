#HomePageGeneralContainer #ControleDiarioTitlePage {
  display: flex;
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}
#HomePageGeneralContainer #ControleDiarioText {
  margin-top: -5px;
  color: #324354;
}
#HomePageGeneralContainer select {
  display: flex;
  flex: 1;
  width: 130px;
  max-height: 50px;
  padding: 0.8em;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  margin-top: -5px;
  color: rgba(49, 49, 49, 0.8352941176);
  font-size: medium;
  align-self: center;
}
#HomePageGeneralContainer input[type=number] {
  display: flex;
  flex: 1;
  width: 100px;
  min-width: 45px;
  max-height: 20px;
  padding: 0.8em;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  margin-top: -5px;
  color: rgba(49, 49, 49, 0.8352941176);
  font-size: medium;
  align-self: center;
}
#HomePageGeneralContainer #controleDiarioStatusContainer {
  display: flex;
  flex: 1;
  width: "100%";
  max-height: -moz-min-content;
  max-height: min-content;
  flex-direction: column;
  align-items: center;
  justify-content: "flex-start";
  margin-top: -25px;
}
#HomePageGeneralContainer #controleDiarioStatusContainer strong {
  color: #313131;
  opacity: 0.8;
  text-transform: uppercase;
  font-size: small;
}
#HomePageGeneralContainer #controleDiarioStatusContainer label {
  font-size: small;
}
#HomePageGeneralContainer #controleDiarioStatus {
  display: flex;
  flex: 1;
  width: "100%";
  flex-direction: row;
  gap: 4em;
}
#HomePageGeneralContainer label {
  text-transform: uppercase;
}
#HomePageGeneralContainer input[type=submit] {
  background-color: #324354;
  width: 10em;
  padding: 0.7em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
  height: 50px;
  align-self: center;
  margin-bottom: 1em;
}
#HomePageGeneralContainer #controleDiarioFormContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 100%;
}
#HomePageGeneralContainer #controleDiarioBloquearOpt {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1em;
}
#HomePageGeneralContainer #controleDiarioFormConfig {
  display: flex;
  flex: 1;
  min-width: "100%";
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
  gap: 1em;
}
#HomePageGeneralContainer #ControleDiarioSubText {
  text-align: justify;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -10px 4em 30px 4em;
  font-size: small;
}
#HomePageGeneralContainer .ControleDiarioPainelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
  align-items: center;
  height: 30em;
  width: 80%;
  max-width: 80em;
  padding: 2em 1em;
  margin-bottom: 5em;
  border-radius: 20px;
  border-top: 40px solid #324354;
  background-color: white;
  box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  text-align: center;
}
#HomePageGeneralContainer h4 {
  text-align: center;
  color: #313131;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: small;
  margin-top: 35px;
  margin-bottom: 5px;
}/*# sourceMappingURL=ControleDiario.css.map */