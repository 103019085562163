.CardDashConteinerBlue {
  background-color: aqua;
  width: 200px;
  height: 100px;
  border-radius: 15px;
  background: rgb(23, 162, 183);
  background: linear-gradient(90deg, rgb(23, 162, 183) 0%, rgb(31, 204, 230) 100%);
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.CardDashConteinerBlue .CardDashInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.CardDashConteinerBlue .CardDashInfo #NumberCardDash {
  font-size: 52px;
}
.CardDashConteinerBlue .CardDashInfo #GroupIcon {
  font-size: 70px;
  color: white;
  opacity: 0.5;
}
.CardDashConteinerBlue #DescriptionDashCard {
  color: white;
  opacity: 0.9;
  margin-left: 1.6em;
  margin-bottom: 1em;
}/*# sourceMappingURL=CardBlue.css.map */