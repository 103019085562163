@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    font-family: 'Poppins', arial;
    background-color: #fafafa;
    margin: 0;
    padding: 0;

    div.container {
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1;
        flex-direction: column;

        .title {
            display: flex;
            flex-direction: column;
            
            margin: 1.5em 0em 1em 5em;

            p.title-page {
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 0px;
                color: #2d78a0;
            }

            p.subtitle-page {
                margin-top: 0px;
                color: #333333;
                opacity: 0.8;
            }
        }

        div.container-informativo {
            display: none;
            align-items: flex-start;
            flex-direction: column;
            margin: 0em 0em -3em 5em;
            background-color: #dadada;
            width: 70vw;
            height: auto;
            padding: 1em;
            border-radius: .5em;

            p.informativo-title {
                font-size: 13px;
                letter-spacing: .1em;
                font-weight: bold;
                
                color: #916f00;
                margin: 0em 0em -.5em 0em;
            }

            p.informativo-desc {
                font-size: 15px;
                color: #333333;
                opacity: 0.8;
                text-align: justify;
            }
        }

        .container-buttons {
            margin: 5em 0em 0em 5em;
            input[type="submit"] {
                background-color: #3080ac;
                outline: none !important;
                border: 0px;
                color: white;
                font-size: 16px;
                padding: 1em;
                margin-right: 1.7em;
                border-radius: .5em;
                font-weight: bold;
                cursor: pointer;
            }

            .lancarButton:hover{
                background-color: #3890c0;
            }
            .gerarButton:hover{
                background-color: #3890c0;
            }
        }
    }
}