.UsuarioDadosContainer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.UsuarioDadosContainer .UsuarioDadosDashContainer {
  display: flex;
}
.UsuarioDadosContainer .UsuarioDadosDashBarraPesquisa {
  display: flex;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.UsuarioDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.UsuarioDadosContainer input {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(186, 186, 186, 0.8705882353);
  max-width: -moz-fit-content;
  max-width: fit-content;
  outline: none;
}
.UsuarioDadosContainer input:hover {
  border-bottom: 1px solid #324354;
}
.UsuarioDadosContainer input:focus {
  border-bottom: 1px solid #324354;
  border-color: #324354;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosIDContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosIDContainer #InputId {
  background-color: rgba(49, 49, 49, 0.1921568627);
  border-radius: 10px;
  padding: 2px 10px;
  text-align: center;
  color: rgba(49, 49, 49, 0.7294117647);
  margin: 1em 0em 0em 0em;
  margin-right: -15px;
  border: none;
  width: 40px;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosNomeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosNomeContainer #UsuarioDadosNomeContainerEdit {
  display: flex;
  flex-direction: row;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosNomeContainer #InputNome {
  margin: 1em 0em 0em 0em;
  min-width: 600px;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosStatusContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 3em 2em 0em;
}
.UsuarioDadosContainer .UsuarioDadosBasicInfoContainer .UsuarioDadosStatusContainer #InputStatus {
  margin: 1em 0em 0em 0em;
  width: 40px;
}
.UsuarioDadosContainer .PermissaoDivDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: -5px;
  font-weight: bold;
  opacity: 0.5;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer {
  display: flex;
  flex-direction: column;
  width: -moz-min-content;
  width: min-content;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .SenhaInfoContainer {
  display: flex;
  flex-direction: row;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .SenhaInfoContainer #EditIconSenha:hover {
  color: #324354;
  cursor: pointer;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .InputSenhaEditarSenha {
  margin: 1em 0em 0em 0em;
  width: -moz-min-content;
  width: min-content;
  cursor: pointer;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .InputSenhaEditarSenha:disabled {
  cursor: default;
  outline: none;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .InputSenhaEditarSenha:hover {
  border-bottom: 1px solid #324354;
  transition: 0.4s;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .InputSenhaEditarSenha:hover:disabled {
  cursor: default;
  outline: none;
}
.UsuarioDadosContainer .UsuarioDadosSenhaContainer .InputSenhaEditarSenha:focus {
  outline: none;
  border-bottom: 1px solid #324354;
  transition: 0.4s ease-out;
}
.UsuarioDadosContainer .disabledBtnEdit {
  cursor: default;
}
.UsuarioDadosContainer #TextPermissoesUsuarioDados {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;
}
.UsuarioDadosContainer .UsuarioDadosButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
}
.UsuarioDadosContainer .linkBtn {
  text-decoration: none;
}/*# sourceMappingURL=UsuariosDados.css.map */