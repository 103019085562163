.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-content-block-button {
  display: flex;
  flex-direction: column;
  min-width: 30em;
  max-width: 30em;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 70vh;
  text-transform: uppercase;
  padding-bottom: 2.5em;
  cursor: default;
}
.modal-content-block-button h2 {
  text-align: center;
  color: #313131;
  opacity: 0.9;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-content-block-button p {
  font-size: 14px;
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -12px 0px 23px 0px;
}
.modal-content-block-button #ButtonModalStudentContainer {
  display: flex;
  max-height: 3em;
  flex-direction: row;
  justify-content: space-between;
}
.modal-content-block-button #ButtonConfirmDash {
  padding: 0.8em 1.5em;
  margin-right: 1.6em;
}

.modal-overlay {
  background-color: rgba(226, 226, 226, 0.6);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DiarioDadosAdmin {
  display: flex;
  justify-content: center;
}
.DiarioDadosAdmin .DiarioDadosContainer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 70vw;
  height: 80vh;
  margin-bottom: 3em;
  background-color: white;
  margin-top: -30px;
  padding-bottom: 20px;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.DiarioDadosAdmin .DiarioDadosContainer .modal-content-block-button {
  display: flex;
  flex-direction: column;
  width: 32em;
  max-width: 32em;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 70vh;
  text-transform: uppercase;
  padding-bottom: 3em;
  cursor: default;
}
.DiarioDadosAdmin .DiarioDadosContainer .modal-content-block-button h2 {
  text-align: center;
  color: #313131;
  opacity: 0.9;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.DiarioDadosAdmin .DiarioDadosContainer .modal-content-block-button p {
  font-size: 14px;
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -12px 0px 23px 0px;
}
.DiarioDadosAdmin .DiarioDadosContainer .modal-content-block-button #ButtonModalStudentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.DiarioDadosAdmin .DiarioDadosContainer .modal-content-block-button #ButtonConfirmDash {
  padding: 0.8em 1.5em;
  margin-right: 1.6em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDashBarraPesquisa {
  display: flex;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  height: 28px;
  min-height: 28px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.DiarioDadosAdmin .DiarioDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.DiarioDadosAdmin .DiarioDadosContainer .TextAulas {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
  max-width: 200px;
}
.DiarioDadosAdmin .DiarioDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.DiarioDadosAdmin .DiarioDadosContainer input {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(186, 186, 186, 0.8705882353);
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 80px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosDataContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosDataContainer #InputId {
  background-color: rgba(49, 49, 49, 0.1921568627);
  color: rgba(49, 49, 49, 0.7333333333);
  border-radius: 10px;
  padding: 2px 10px;
  margin-right: -5px;
  margin: 1em 0em 0em 0em;
  border: none;
  width: 70px;
  text-align: center;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosCursoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosCursoContainer #InputNome {
  margin: 1em 0em 0em 0em;
  min-width: 95%;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosTurmaContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 3em 2em 0em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainer .DiarioDadosTurmaContainer #InputStatus {
  margin: 1em 0em 0em 0em;
  width: 40px;
}
.DiarioDadosAdmin .DiarioDadosContainer #DescricaoDiarioTitle {
  color: #313131;
  font-weight: bold;
}
.DiarioDadosAdmin .DiarioDadosContainer .DescricaoDiarioContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 4em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DescricaoDiarioContainer textarea {
  display: flex;
  flex: 1;
  border-radius: 10px;
  overflow-clip-margin: none;
  padding: 10px;
  resize: none;
  border: 1px solid rgba(50, 67, 84, 0.2666666667);
}
.DiarioDadosAdmin .DiarioDadosContainer .DescricaoDiarioContainer #ConteudosDiario {
  height: 5vh;
}
.DiarioDadosAdmin .DiarioDadosContainer .DescricaoDiarioContainer #AtividadeAulaDiario {
  height: 40px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DescricaoDiarioContainer #AtividadeCasaDiario {
  height: 40px;
  margin-bottom: 2px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 70px;
  max-width: 40%;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosDisciplinaContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0.5em -20.5em 0em 2.5em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosDisciplinaContainer #InputDisciplina {
  margin: 0.5em 0em 0em 0em;
  min-width: 80px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosAulasContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0.5em 0em 1em 0em;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosAulasContainer #InputAulas {
  margin: 0.5em 0em 0em 0em;
  width: 40px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosBloquadoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1.2em;
  margin-left: 8em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosBasicInfoContainerRow2 .DiarioDadosBloquadoContainer .BloqueadoContainer {
  margin-top: 0.5em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 40em;
  margin: 0.5em 0em 0em 2.5em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer .DisciplinaInfoContainer,
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer .AulasInfoContainer {
  display: flex;
  flex-direction: row;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer .DisciplinaInfoContainer #EditIconDisciplina:hover,
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer .AulasInfoContainer #EditIconDisciplina:hover {
  cursor: default;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer #InputDisciplina {
  display: flex;
  flex: 1;
  margin: 1em 0em 0em 0em;
  min-width: 300px;
  cursor: default;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer #InputDisciplina:hover {
  cursor: default;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosDisciplinaContainer #InputDisciplina:focus {
  outline: none;
}
.DiarioDadosAdmin .DiarioDadosContainer #TextPermissoesDiarioDados {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0em 0em;
  max-height: 15px;
}
.DiarioDadosAdmin .DiarioDadosContainer .descricaoDiario {
  font-size: 10px;
  opacity: 0.8;
  margin-bottom: 4px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 60px;
  align-items: flex-end;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer div#buttonBackDiarioDados #ButtonBackDash {
  background-color: #324354;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer div#buttonBackDiarioDados #ButtonConfirmDash {
  margin: 1.4em 0em 0.9em 1.3em;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer #ButtonModalStudent {
  margin: 0px 0px 11px 20px;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer #ButtonBack {
  margin-left: 20px;
  text-decoration: none;
}
.DiarioDadosAdmin .DiarioDadosContainer .DiarioDadosButtonsContainer #ButtonDeleteContainerDiarioDados {
  display: flex;
  flex: 1;
  justify-content: right;
  align-items: center;
  margin-right: 4em;
}

#TitleModalDiarioDados {
  display: flex;
  justify-content: center;
  align-items: center;
}
#TitleModalDiarioDados #WarningIconDiarioDados {
  color: rgb(231, 129, 39);
  font-size: larger;
  margin-right: 0.5em;
}/*# sourceMappingURL=DiarioDados.css.map */