#ButtonConfirmDash {
  background-color: #3BB147;
  border: 1px solid #3BB147;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 1.5em 0em 1em 3em;
  padding: 0.6em 2em;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 8px 5px -7px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
}

#ButtonConfirmDash:hover {
  cursor: pointer;
  background-color: #3cc94a;
  border: 1px solid #3cc94a;
  transition: 0.2s;
}/*# sourceMappingURL=ButtonConfirm.css.map */