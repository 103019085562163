#RelatorioCompletoGeneralContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoContainer #RelatorioCompletoTitlePage {
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
  align-items: center;
  height: 30em;
  width: 80%;
  max-width: 80em;
  margin-bottom: 5em;
  border-radius: 20px;
  border-top: 40px solid #324354;
  background-color: white;
  box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer #containerFormDiario {
  display: flex;
  flex-direction: column;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer input {
  align-self: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.3em;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer input[type=submit] {
  width: 10em;
  padding: 0.7em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: white;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer #confirmar {
  background-color: #324354;
  cursor: pointer;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer #confirmar:hover {
  background-color: #4a637e;
}
#RelatorioCompletoGeneralContainer .RelatorioCompletoPainelContainer #disabled:disabled:hover {
  background-color: #5c7988;
  cursor: wait;
}/*# sourceMappingURL=RelatorioNotasAuxiliares.css.map */