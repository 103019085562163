.GerenciarGeneralAdminContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide #GerenciarTitlePage {
  display: flex;
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer {
  display: flex;
  flex: 1;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 60em;
  margin-bottom: 5em;
  border-radius: 20px;
  border-top: 40px solid #324354;
  background-color: white;
  box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 12em;
  height: 15em;
  border-radius: 10px;
  border: 4px solid rgba(50, 67, 84, 0.1921568627);
  text-decoration: none;
  color: #324354;
  font-weight: bold;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar div {
  opacity: 0.7;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar .IconGerenciarPainel {
  font-size: 80px;
  opacity: 0.7;
  color: #324354;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar:hover {
  cursor: pointer;
  border: 4px solid rgba(50, 67, 84, 0.8588235294);
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  transition: 0.5s;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar:hover .IconGerenciarPainel {
  transition: 0.5s;
  opacity: 1;
}
.GerenciarGeneralAdminContainer .GerenciarPageRigthSide .GerenciarBodyPainelContainer .CardGerenciar:hover div {
  opacity: 1;
  transition: 0.5s;
}/*# sourceMappingURL=Gerenciar.css.map */