.TabelaGeralContainerPermissaoUsuario {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 3em;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.5764705882);
  overflow: hidden;
  height: 220px;
}
.TabelaGeralContainerPermissaoUsuario #linhaTabelaPermissaoUsuario {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid rgba(197, 197, 197, 0.4941176471);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.TabelaGeralContainerPermissaoUsuario #linhaTabelaPermissaoUsuario #itemTabelaPermissaoUsuario {
  display: flex;
  flex-direction: row;
  margin-left: 4em;
}
.TabelaGeralContainerPermissaoUsuario #linhaTabelaPermissaoUsuario div {
  padding: 0.2em;
}
.TabelaGeralContainerPermissaoUsuario #linhaTabelaPermissaoUsuario div#itemTabelaPermissaoUsuario:hover {
  background-color: rgba(224, 224, 224, 0.4941176471);
}
.TabelaGeralContainerPermissaoUsuario table {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  border-collapse: collapse;
}
.TabelaGeralContainerPermissaoUsuario table thead th {
  background: #f3f3f3;
  color: rgba(51, 51, 51, 0.631372549);
  padding: 0.5em;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d3;
  position: sticky;
  top: 10;
}/*# sourceMappingURL=TabelaPermissaoNovoUsuario.css.map */