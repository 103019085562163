body {
  margin: 0;
  padding: 0;
}
body #HomePageGeneralContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}
body #HomePageGeneralContainer #HomePageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
body #HomePageGeneralContainer #HomePageContainer .HomePageCardsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  opacity: 0;
}/*# sourceMappingURL=Home.css.map */