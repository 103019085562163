.NotasTabelaGeralContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 3em;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.5764705882);
  max-height: -moz-min-content;
  max-height: min-content;
  overflow-x: hidden;
}
.NotasTabelaGeralContainer #srollInvisibleHeadItem {
  width: 6px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer :nth-child(2) {
  display: flex;
  min-width: 50%;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 36px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow #itemBodyNotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  padding: 0.5em;
  border: 1px solid rgba(197, 197, 197, 0.4941176471);
  border-collapse: collapse;
  justify-content: center;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow #visualizarQualitativasTabelaButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  color: #324354;
  background-color: transparent;
  border-collapse: collapse;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow #visualizarQualitativasTabelaButton #iconSearch {
  color: #324354;
  margin-right: 2px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow #visualizarQualitativasTabelaButton:hover {
  cursor: pointer;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow:hover {
  background-color: rgba(224, 224, 224, 0.4941176471);
}
.NotasTabelaGeralContainer :nth-child(2) {
  display: flex;
  min-width: 50%;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable {
  display: flex;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-collapse: collapse;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable #itemHeaderNotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  padding: 0.5em;
  border-collapse: collapse;
  justify-content: center;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable div {
  background: #f3f3f3;
  color: rgba(51, 51, 51, 0.631372549);
  padding: 0.5em;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d3;
  position: sticky;
  top: 0;
}
.NotasTabelaGeralContainer .NotasDadosButtonsContainer {
  margin-top: -15px;
  background-color: red;
}

#TitleModalNotasQuaAlunoQuadro {
  color: #324354;
}

#qualitativasTabelaAlunosProgress {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2;
}

#notasQuaAlunoGrade {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  scrollbar-width: thin;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItemHeader {
  display: flex;
  padding: 0.5em 1.2em;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItemHeader #notasQuaAlunoGradeItemDescricaoHeader {
  display: flex;
  flex: 2;
  font-weight: bold;
  opacity: 0.8;
  font-size: 18px;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItemHeader #notasQuaAlunoGradeItemConceitoHeader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 6.5em;
  border-radius: 5px;
  font-weight: bold;
  opacity: 0.8;
  font-size: 18px;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItem {
  display: flex;
  padding: 0.5em 1.2em;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItem #notasQuaAlunoGradeItemDescricao {
  display: flex;
  flex: 2;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItem #notasQuaAlunoGradeItemConceito {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 6em;
  background-color: rgba(50, 67, 84, 0.1333333333);
  border-radius: 5px;
}
#notasQuaAlunoGrade #notasQuaAlunoGradeItem:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}/*# sourceMappingURL=QualitativasTabelaAlunos.css.map */