@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  font-family: "Poppins", arial;
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}
body .container {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body .container header {
  background-color: #368EBD;
  min-height: 25vh;
  width: 100%;
  margin: 0px;
}
body .container header .header-infodat-label {
  color: white;
  margin: -15vh 0em 0em 0.5vw;
}
body .container .icon-svg {
  margin-top: -2em;
}
body .container .loading {
  position: absolute;
}
body .container #PainelAdministrativoLink {
  display: flex;
  color: #3080ac;
  width: 85%;
  padding-left: 5px;
  margin: 12px 0px 4px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  letter-spacing: normal;
}
body .container #PainelAdministrativoLink a:hover {
  color: #3693c5;
  text-decoration: none;
}
body .container .professor-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1em;
  margin-top: -7vh;
  min-width: 15em;
  max-width: 19em;
}
body .container .professor-form-card h1 {
  color: #333333;
}
body .container .professor-form-card select {
  color: #333333;
  margin-bottom: 5px;
  width: 85%;
  font-size: 16px;
  background-color: #fafafa;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
}
body .container .professor-form-card select option {
  margin: 0px;
  padding: 5px;
  font-size: 16px;
  color: #333333;
  background-color: white;
}
body .container .professor-form-card select option:nth-child(even) {
  background-color: rgba(196, 229, 247, 0.2392156863);
}
body .container .professor-form-card select #escolaOption {
  color: #bababa;
}
body .container .professor-form-card select #escolaOption:focus {
  color: red;
}
body .container .professor-form-card select#professores:focus div.option-Professores {
  height: 30px;
}
body .container .professor-form-card select#professores:focus option {
  margin: 0px;
  padding: 5px;
  font-size: 16px;
  color: #333333;
  background-color: white;
}
body .container .professor-form-card select#professores:focus option:nth-child(even) {
  background-color: rgba(196, 229, 247, 0.2392156863);
}
body .container .professor-form-card select:focus {
  outline: none !important;
  border: 1px solid #368EBD;
  box-shadow: 0 0 10px rgba(170, 231, 255, 0.4588235294);
}
body .container .professor-form-card input.password {
  width: 80%;
  font-size: 16px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
body .container .professor-form-card input.password:focus {
  outline: none !important;
  border: 1px solid #3080ac;
  box-shadow: 0 0 10px rgba(170, 228, 255, 0.6039215686);
  padding: 5px;
  color: #1b85be;
}
body .container .professor-form-card input.button {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-image: linear-gradient(#36bd41, #23832b);
  background-color: #36bd41;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-width: 0px;
  border-radius: 0.5em;
  cursor: pointer;
}
body .container .professor-form-card input.button:hover {
  box-shadow: 0 0 10px rgba(127, 255, 138, 0.8078431373);
  background-image: linear-gradient(#3ed44a, #1d9127);
  background-color: rgba(139, 228, 146, 0.5607843137);
  transition: 0.5s ease;
}
body .container .professor-form-card input.button:focus {
  outline: none !important;
}
body .container .professor-form-card Link {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: auto;
  width: 10em;
  height: 2.4em;
  text-decoration: none;
  margin: 0.5em 0em 1.5em 0em;
  cursor: pointer;
}
@media (max-width: 320px) {
  body .container .professor-form-card {
    padding: 2.5em 1em;
    width: 85vw;
    margin-right: -0.7em;
  }
}/*# sourceMappingURL=App.css.map */