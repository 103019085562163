#ContainerModalStudent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.modal-overlay {
  background-color: rgba(226, 226, 226, 0.6);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-content-student-falta {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 700px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 70vh;
  text-transform: uppercase;
  cursor: default;
}
.modal-content-student-falta h2 {
  text-align: center;
  color: #313131;
  opacity: 0.9;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-content-student-falta p {
  font-size: 14px;
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -12px 0px 23px 0px;
}
.modal-content-student-falta #ButtonModalStudentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.modal-content-student-falta #ButtonModalStudentContainer #AlunosQtdInfoStudentModal {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
  color: #313131;
  opacity: 0.5;
}

#ButtonModalStudent {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #324354;
  border: 1px solid #324354;
  width: -moz-max-content;
  width: max-content;
  min-width: 100px;
  height: -moz-max-content;
  height: max-content;
  margin: 1.5em 0em 0.5em 2em;
  padding: 0.6em 1em;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
}
#ButtonModalStudent #CloseIconModalStudent {
  padding-right: 5px;
  font-size: 20px;
  margin: 0;
}

#tabelaAlunosModal {
  display: flex;
  flex-direction: column;
  flex: 6;
  border: 1px solid rgba(189, 200, 220, 0.3);
  border-radius: 10px;
  padding: 0px 0px 0px 0px;
  max-height: 100%;
  overflow-y: hidden;
  font-size: 14px;
}
#tabelaAlunosModal #tabelaAlunosModalHeaderTitles {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 2.5em;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(189, 200, 220, 0.18);
}
#tabelaAlunosModal #tabelaAlunosModalHeaderTitles div:nth-child(2) {
  padding-left: 1em;
}
#tabelaAlunosModal #tabelaAlunosModalHeaderTitles div:nth-child(3) {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  text-align: center;
  margin-right: 15px;
  padding-right: 15px;
}
#tabelaAlunosModal #tabelaAlunosModalHeaderTitles div {
  padding: 8px 10px 8px 10px;
  border-right: 1px solid rgba(189, 200, 220, 0.5);
}
#tabelaAlunosModal #tabelaAlunosModalHeaderTitles:nth-child(odd) {
  background-color: rgba(189, 200, 220, 0.18);
  border: 1px solid rgba(189, 200, 220, 0.18);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#tabelaAlunosModal #tabelaAlunosModalBodyItems {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
}
#tabelaAlunosModal #tabelaAlunosModalRow {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 6px 20px;
}
#tabelaAlunosModal #tabelaAlunosModalRow:nth-child(odd) {
  background-color: rgba(189, 200, 220, 0.18);
}
#tabelaAlunosModal #tabelaAlunosModalRow:hover {
  background-color: rgba(189, 200, 220, 0.3);
}
#tabelaAlunosModal #bodyItemAlunosModal:nth-child(1) {
  width: 90px;
  max-width: 90px;
}
#tabelaAlunosModal #bodyItemAlunosModal:nth-child(2) {
  width: 470px;
  max-width: 470px;
}
#tabelaAlunosModal #bodyItemAlunosModal:nth-child(2) div {
  background-color: red;
}
#tabelaAlunosModal #bodyItemAlunosModal:nth-child(3) {
  width: 50px;
  max-width: 50px;
  text-align: center;
}

button:hover {
  background-color: #3d5368;
  cursor: pointer;
  border: 1px solid #3d5368;
  transition: 0.2s;
  text-decoration: none;
}/*# sourceMappingURL=ButtonModalStudent.css.map */