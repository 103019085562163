@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  font-family: "Poppins", arial;
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none;
  cursor: pointer;
}

#AppHeader {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #3080ac;
  width: 100%;
  height: 4.5em;
  color: #fefefe;
  padding: 0em 0em 0em 2em;
}
#AppHeader a {
  color: #fefefe;
  text-decoration: none;
}
#AppHeader h1 {
  color: #fafafa;
}
#AppHeader .menu {
  display: flex;
  width: 30vw;
  float: right;
  justify-content: space-between;
  align-items: center;
  padding: 0em 0em 0em 20vw;
  letter-spacing: 0.1em;
}
#AppHeader .menu .menu-notas {
  margin-right: 3em;
  margin-left: 2em;
  opacity: 1;
}
#AppHeader .menu .menu-notas a {
  cursor: pointer;
}
#AppHeader .menu .item-menu::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
}
#AppHeader .menu .item-menu:hover::after {
  width: 100%;
  transition: width 0.3s;
}
#AppHeader .menu .menu-diario {
  margin-left: 2em;
  padding-right: 1.5em;
}
#AppHeader .menu .menu-inicio {
  margin-left: 2em;
  padding-right: 2em;
}
#AppHeader .menu .menu-pedagogico {
  position: static;
}
#AppHeader .button-sair {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  max-width: 5em;
  margin: 0em 0em 0em 30vw;
  background-color: #3080ac;
}
#AppHeader .button-sair p {
  margin-right: 8px;
}

@media (max-width: 1084px) {
  header {
    width: auto;
  }
  header .menu {
    padding: 0em 0em 0em 8em;
  }
  header .button-sair {
    margin: 0em 0em 0em 28vw;
  }
}
@media (max-width: 928px) {
  header .menu {
    padding: 0em 0em 0em 2em;
  }
  header .menu-pedagogico {
    display: none;
  }
  header .button-sair {
    margin: 0em 0vw 0em 0vw;
  }
}
@media (max-width: 771px) {
  header#AppHeader .menu {
    padding: 0em 0em 0em 1em;
  }
  header#AppHeader .menu .menu-inicio {
    margin: 0em 1em 0em 3em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-diario {
    margin: 0em 1em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-notas {
    margin: 0em 1em;
    padding: 0em;
  }
  header#AppHeader .button-sair {
    font-size: 16px;
    margin: 0em 15vw 0em 24vw;
  }
}
@media (max-width: 649px) {
  header#AppHeader {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  header#AppHeader h1 {
    display: none;
  }
  header#AppHeader .menu {
    flex: 2;
  }
  header#AppHeader .menu .menu-inicio {
    margin: 0em 1em 0em 3em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-diario {
    margin: 0em 1em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-notas {
    margin: 0em 1em;
    padding: 0em;
  }
  header#AppHeader .button-sair {
    padding: 0;
  }
}
@media (max-width: 649px) {
  header#AppHeader {
    font-size: 16px;
  }
  header#AppHeader h1 {
    display: none;
  }
  header#AppHeader .menu {
    flex: 2;
  }
  header#AppHeader .menu .menu-inicio {
    margin: 0em 0.5em 0em 1em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-diario {
    margin: 0em 0.5em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-notas {
    margin: 0em 0.5em;
    padding: 0em;
  }
  header#AppHeader .button-sair {
    font-size: 14px;
    margin: 0em 10vw 0em 25vw;
  }
}
@media (max-width: 600px) {
  header#AppHeader {
    font-size: 16px;
  }
  header#AppHeader h1 {
    display: none;
  }
  header#AppHeader .menu {
    flex: 2;
    margin-left: 0px;
    padding: 0em 0em 0em 0em;
  }
  header#AppHeader .menu .menu-inicio {
    margin: 0em 0.5em 0em 1em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-diario {
    margin: 0em 0.5em;
    padding: 0em;
  }
  header#AppHeader .menu .menu-notas {
    margin: 0em 0.5em;
    padding: 0em;
  }
  header#AppHeader .button-sair {
    font-size: 16px;
    margin: 0em 2vw 0em 22vw;
  }
}/*# sourceMappingURL=header.css.map */