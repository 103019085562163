@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  font-family: "Poppins", arial;
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}
body div.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}
body div.container header {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #3080ac;
  width: 100%;
  height: 4em;
  color: #fefefe;
  padding: 0em 0em 0em 2em;
}
body div.container header .menu {
  display: flex;
  width: 30vw;
  float: right;
  justify-content: space-between;
  align-items: center;
  padding: 0em 0em 0em 20vw;
  letter-spacing: 0.1em;
}
body div.container header .button-sair {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 0em 0em 0em 30vw;
}
body div.container header .button-sair p {
  margin-right: 8px;
}
body div.container .title {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0em 0.5em 5em;
}
body div.container .title p.title-page {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
  color: #2d78a0;
}
body div.container .title p.subtitle-page {
  margin-top: 0px;
  color: #333333;
  opacity: 0.8;
}
body div.container section {
  width: 100%;
}
body div.container section .info-bar {
  display: flex;
  width: 100%;
  justify-content: initial;
  align-items: center;
  padding-right: 2em;
}
body div.container .conteudo {
  min-width: 100%;
}
body div.container div.container-informativo {
  display: none;
  flex-direction: column;
  margin: 0em 0em 0em 5em;
  background-color: #e4e4e4;
  width: 25em;
  height: 5vh;
  padding: 1em;
  border-radius: 0.5em;
  margin-left: 100vh;
  align-items: flex-end;
  padding-bottom: 1.8em;
}
body div.container div.container-informativo p.informativo-title {
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #916f00;
  margin: 0em 0em -0.5em 0em;
}
body div.container div.container-informativo p.informativo-desc {
  font-size: 11px;
  color: #333333;
  opacity: 0.7;
  text-align: justify;
}
body div.container div.container-form {
  display: flex;
  flex-direction: column;
  margin: 2em 60em 0em 6em;
  min-width: 45em;
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}
body div.container div.container-form label {
  color: #333333;
}
body div.container div.container-form #consultarConteudoProgContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: calc(100% - 5em);
  justify-content: space-between;
  margin-bottom: 0.2em;
  align-items: flex-end;
}
body div.container div.container-form #consultarConteudoProgBtn {
  margin: 0;
  background-color: #2d78a0;
  color: white;
  height: 1.8em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 2px;
  padding-right: -1px;
}
body div.container div.container-form label#TotalAlunosDiarioLancar {
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  color: #313131;
  opacity: 0.5;
  margin-right: 3.4em;
  margin-top: -26px;
  margin-bottom: 18px;
  font-size: 14px;
}
body div.container div.container-form label#TotalTextoDiarioLancar {
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  color: #313131;
  opacity: 0.5;
  margin-right: 4.9em;
  margin-top: -10px;
  font-size: 10px;
}
body div.container div.container-form input[type=checkbox] {
  width: 1.1em;
  height: 1.1em;
  margin: 0em 2px 0.5em 0.5em;
}
body div.container div.container-form #conteudo {
  margin-top: 0;
  min-width: 43em;
  max-width: 43em;
  margin-right: 0.5em;
}
body div.container div.container-form #conteudoContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
body div.container div.container-form #atividade_aula {
  min-width: 43.3em;
  margin-right: 0.5em;
}
body div.container div.container-form #atividade_casa {
  min-width: 43.3em;
  margin-right: 0.5em;
}
body div.container div.container-form #colar {
  margin: 0;
  background: url("../../../images/edit-white.svg") no-repeat 4px;
  background-size: 20px;
  background-color: #2d78a0;
  color: white;
  width: 2em;
  height: 1.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 2px;
  padding-right: -1px;
}
body div.container div.container-form #colar:hover {
  background-color: #3080ac;
}
body div.container div.container-form input {
  margin-top: 0.4em;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.3em;
}
body div.container div.container-form select {
  margin-top: 0.2em;
  margin-bottom: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.3em;
  max-width: 43em;
}
body div.container div.container-form select:focus {
  border: 1px solid #3797ca;
  max-width: 43em;
}
body div.container div.container-form select#turma {
  width: 4em;
}
body div.container div.container-form input[type=text] {
  padding: 0.3em;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
body div.container div.container-form input[type=text]:focus {
  border: 1px solid rgba(48, 129, 172, 0.6588235294);
  outline: 1px solid rgba(58, 175, 238, 0.6588235294);
}
body div.container div.container-form input[type=text]::-moz-placeholder {
  color: rgba(72, 120, 146, 0.6588235294);
}
body div.container div.container-form input[type=text]::placeholder {
  color: rgba(72, 120, 146, 0.6588235294);
}
body div.container div.container-form input[type=date] {
  width: 10em;
}
body div.container div.container-form input[type=date]:focus {
  border: 1px solid rgba(48, 129, 172, 0.6588235294);
  outline: 1px solid rgba(48, 129, 172, 0.6588235294);
}
body div.container div.container-form input[type=number] {
  width: 3em;
  padding: 0.3em;
}
body div.container div.container-form input[type=number]:focus {
  border: 1px solid rgba(48, 129, 172, 0.6588235294);
  outline: 1px solid rgba(48, 129, 172, 0.6588235294);
}
body div.container div.container-form input[type=submit] {
  margin-bottom: 7em;
  width: 10em;
  padding: 0.7em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: white;
}
body div.container div.container-form #confirmar {
  background-color: #2d78a0;
}
body div.container div.container-form #confirmar:hover {
  background-color: #3293c7;
}
body div.container div.container-form #confirmar:disabled {
  background-color: #5c7988;
  cursor: wait;
}
body div.container div.container-form #confirmar:disabled:hover {
  background-color: #5c7988;
  cursor: wait;
}
body div.container div.container-form input[type=submit]:hover {
  background-color: #3080ac;
  cursor: pointer;
}
body div.container div.container-form .aulasContainer {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
body div.container div.container-form .aulasContainer .aulaCheckContainer {
  display: flex;
  align-items: center;
  padding-bottom: 7px;
}
body div.container div.container-form .aulasContainer .aulaCheckContainer .editarCheckText {
  margin: 0 0 0 3px;
  padding: 0;
  color: #313131;
  font-weight: normal;
  opacity: 0.8;
}
body div.container div.container-form .aulasContainer .aulaCheckContainer #aulasEdit {
  margin: 0 0 0 1.5em;
  size: 25px;
}
body div.container div.container-form #deletar {
  background-color: #bd3434;
  cursor: pointer;
}
body div.container div.container-form #deletar:hover {
  background-color: #ce3434;
}
body div.container div.container-form #deletar:disabled {
  cursor: default;
  background-color: #5c7988;
  cursor: default;
}
body div.container div.container-form textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
  resize: none;
  margin-bottom: 1em;
  max-width: 41.5em;
}
body div.container div.container-form textarea:focus {
  border: 1px solid rgba(48, 129, 172, 0.6588235294);
  outline: 1px solid rgba(58, 175, 238, 0.6588235294);
}
body div.container div.container-form textarea::-moz-placeholder {
  color: rgba(72, 120, 146, 0.7725490196);
}
body div.container div.container-form textarea::placeholder {
  color: rgba(72, 120, 146, 0.7725490196);
}
body div.container div.container-form details {
  margin: 0em 0em 2em 0em;
  max-width: 41.8em;
}
body div.container div.container-form details summary {
  border-radius: 0.3em;
  padding: 0.3em 0em 0.3em 0.8em;
  background-color: #2d78a0;
  color: white;
  outline: none;
  margin-bottom: -1px;
  cursor: pointer;
}
body div.container div.container-form details summary:hover {
  background-color: #3080ac;
}
body div.container div.container-form details table {
  border-bottom: 2px solid #2d78a0;
  border-right: 2px solid #2d78a0;
  border-left: 2px solid #2d78a0;
  border-spacing: 0px;
  width: 100%;
}
body div.container div.container-form details table tr, body div.container div.container-form details table td {
  border: 1px solid rgba(173, 173, 173, 0.5411764706);
  padding-left: 0.5em;
}
body div.container div.container-form details table tr .RegistroFaltas, body div.container div.container-form details table td .RegistroFaltas {
  color: #888888;
  opacity: 0.8;
  text-align: center;
  font-weight: normal;
}
body div.container div.container-form details table tr:nth-child(even) {
  background-color: #f2f2f2;
}
body div.container div.container-form details table th {
  border-bottom: 1px solid rgba(173, 173, 173, 0.5411764706);
  border-right: 1px solid rgba(173, 173, 173, 0.5411764706);
  border-left: 1px solid rgba(173, 173, 173, 0.5411764706);
  padding: 0.5em 0em 0.5em 0em;
  cursor: default;
}
body div.container div.container-form details table th[title]:hover::after {
  background-color: #2d78a0;
  color: white;
}
body div.container div.container-form details table td.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 2px 5px;
}
body div.container div.container-form details table tr td {
  text-align: left;
  padding-left: 8px;
}
body div.container div.container-form .loadingLançar {
  background-image: url("../../../images/Rolling-1s-200px.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding-right: 0.5em;
}

@media (max-width: 769px) {
  body {
    margin: 0;
    padding: 0;
  }
  div.container section #infoBarDiario {
    width: auto;
    padding-right: 0em;
  }
  div.container section #infoBarDiario .title {
    margin: 0.5em 0em 0em 2em;
  }
  div.container #colar {
    display: none;
  }
  div.container div#containerFormDiario {
    margin: 1em 0em 0em 0em;
    padding: 0em 0em 0em 2em;
    min-width: 95vw;
    max-width: 95vw;
  }
  div.container div#containerFormDiario label {
    width: 50vw;
  }
  div.container div#containerFormDiario #observacao {
    color: #755020;
    opacity: 0.8;
    font-weight: normal;
    margin-bottom: -0.5em;
    margin-top: 1em;
  }
  div.container div#containerFormDiario #curso {
    max-width: 84vw;
  }
  div.container div#containerFormDiario #disciplina {
    max-width: 84vw;
  }
  div.container div#containerFormDiario #conteudo {
    min-width: 84vw;
  }
  div.container div#containerFormDiario #atividade_aula {
    min-width: 85vw;
  }
  div.container div#containerFormDiario #atividade_casa {
    min-width: 85vw;
  }
  div.container div#containerFormDiario #data {
    font-size: 14px;
  }
  div.container div#containerFormDiario details {
    min-width: 90vw;
    width: 90vw;
    max-width: 90vw;
    font-size: 12px;
  }
  div.container div#containerFormDiario details table #faltasTitleGridDiario {
    min-width: 55px;
  }
  div.container div#containerFormDiario details table #checkBoxContainerDiario {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  div.container div#containerFormDiario details #matriculaTitleGridDiario {
    display: none;
  }
  div.container div#containerFormDiario details #matriculaFieldGridDiario {
    display: none;
  }
  div.container div#containerFormDiario details #nomeFieldTitleDiario {
    width: -moz-min-content;
    width: min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }
  div.container div#containerFormDiario details #nomeFieldGridDiario {
    width: -moz-min-content;
    width: min-content;
    max-width: -moz-min-content;
    max-width: min-content;
    font-weight: normal;
  }
}
@media (max-width: 563px) {
  div.container section #infoBarDiario .title {
    margin: 0.5em 0em 0em 1em;
  }
  div.container div#containerFormDiario {
    padding: 0em 0em 0em 1em;
  }
  div.container div#containerFormDiario details #nomeFieldGridDiario {
    font-size: 10px;
  }
  div.container div#containerFormDiario details input[type=checkbox] {
    width: 1em;
    height: 1em;
    margin: 0em 2px 0.3em 0.3em;
  }
}
@media (max-width: 378px) {
  div#containerFormDiario details summary #faltasTitleGridDiario {
    width: 100%;
  }
  div#containerFormDiario details #nomeFieldGridDiario {
    font-size: 10px;
  }
  div#containerFormDiario details #lancadosTitleGridDiario {
    display: none;
  }
  div#containerFormDiario details input[type=checkbox] {
    width: 1em;
    height: 1em;
    margin: 0em 2px 0.3em 0.3em;
  }
}
@media (max-width: 338px) {
  div.container div#containerFormDiario label {
    font-size: 12px;
  }
  div.container div#containerFormDiario input {
    font-size: 14px;
  }
  div.container div#containerFormDiario #conteudo {
    min-width: 80vw;
  }
  div.container div#containerFormDiario #atividade_aula {
    min-width: 81vw;
  }
  div.container div#containerFormDiario #atividade_casa {
    min-width: 81vw;
  }
  div.container div#containerFormDiario #confirmar {
    font-size: 14px;
    margin: 0em 1.7em 0em 0em;
    padding: 0.5em 1em;
    width: -moz-fit-content;
    width: fit-content;
  }
  div.container div#containerFormDiario #deletar {
    font-size: 14px;
    margin: 0em 0em 4em 0em;
    padding: 0.5em 1em;
    width: -moz-fit-content;
    width: fit-content;
  }
  div.container div#containerFormDiario #colar {
    margin-left: 0em;
    width: 24px;
    height: 24px;
    background-size: 18px;
  }
  div.container div#containerFormDiario details table #faltasTitleGridDiario {
    min-width: 55px;
  }
  div.container div#containerFormDiario details table #checkBoxContainerDiario {
    margin: 0;
    padding: 0;
    text-align: center;
  }
}/*# sourceMappingURL=Lancar.css.map */