.NotasTabelaDashContainer {
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
.NotasTabelaDashContainer .NotasTabelaDashTable {
  display: flex;
  justify-content: center;
  max-height: 87%;
  overflow-y: scroll;
  cursor: default;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table {
  display: table;
  width: 90%;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table thead tr, .NotasTabelaDashContainer .NotasTabelaDashTable table th {
  padding: 8px 2px 8px 10px;
  color: rgba(51, 51, 51, 0.631372549);
  text-align: left;
  font-size: 14px;
  background-color: #fafafa;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table thead th {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody {
  overflow: scroll;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody tr td {
  padding: 8px 2px 8px 10px;
  max-height: 10px;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody tr {
  max-height: 10px;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody tr:hover {
  background-color: #ececec;
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody tr:nth-child(even) {
  background-color: rgba(189, 200, 220, 0.1882352941);
}
.NotasTabelaDashContainer .NotasTabelaDashTable table tbody tr:nth-child(even):hover {
  background-color: #e4e4e4;
}
.NotasTabelaDashContainer .NotasTabelaDashTable #NotasTabelaDashRow:hover {
  background-color: #ececec;
}
.NotasTabelaDashContainer .NotasTabelaDashTable #IconEditProfessor {
  cursor: pointer;
  color: #324354;
  margin: 2px;
}
.NotasTabelaDashContainer .NotasTabelaDashTable #IconViewNotas {
  cursor: pointer;
}
.NotasTabelaDashContainer #ContainerIconSearchLoadingStatusNotas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.8;
}
.NotasTabelaDashContainer #ContainerIconSearchLoadingStatusNotas img#IdIconSearchLoadingStatus {
  width: 130px;
  height: 130px;
}/*# sourceMappingURL=QualitativasTabela.css.map */