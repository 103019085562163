.ProfessoresTabelaDashContainer {
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashBarraPesquisa {
  display: flex;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  height: 28px;
  justify-content: center;
  align-items: center;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashBarraPesquisa input {
  border-radius: 10px;
  width: 40%;
  height: 20px;
  margin-left: 1em;
  border-width: 0px;
  padding: 2px 10px;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable {
  display: flex;
  justify-content: center;
  max-height: 83%;
  overflow-y: scroll;
  cursor: default;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table {
  display: table;
  width: 90%;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table thead tr, .ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table th {
  padding: 8px 2px 8px 10px;
  color: rgba(51, 51, 51, 0.631372549);
  text-align: left;
  font-size: 14px;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table thead th {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table tbody {
  overflow: scroll;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table tbody tr td {
  padding: 8px 2px 8px 10px;
  max-height: 10px;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table tbody tr {
  max-height: 10px;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable table tbody tr:hover {
  background-color: #ececec;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable #ContainerIconSearchLoadingStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.8;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable #ContainerIconSearchLoadingStatus img#IdIconSearchLoadingStatus {
  width: 130px;
  height: 130px;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable #TabelaPessoasDashRow:hover {
  background-color: #ececec;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable #ProfessoresTabelaDashRow:hover {
  background-color: #ececec;
}
.ProfessoresTabelaDashContainer .ProfessoresTabelaDashTable #IconEditProfessor {
  cursor: pointer;
  color: #324354;
  margin: 2px;
}
.ProfessoresTabelaDashContainer #QtdProfessoresTabelaProfessores {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 1em 3em;
  color: #324354;
  opacity: 0.7;
}/*# sourceMappingURL=ProfessoresTabela.css.map */