.UsuarioNovoDadosContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 70vw;
    height: 72vh;
    background-color:white;
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 
    cursor: default;

    .UsuarioNovoDadosDashContainer {
        display: flex;
        
    }
    
    .UsuarioNovoDadosSenhaContainerGeral {
        display: flex;
    }

    .UsuarioNovoDadosDashBarraPesquisa{
        display: flex;
        background-color: #324354;
        color: white;
        padding: 0.5em;
        height: 28px;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
    }
    
    .TextDescricao {
        font-weight: bold;
        color: #3333339c;
    }

    input {
        background-color: white;
        border: none;
        border-bottom: 1px solid #bababade;
        max-width: fit-content;
        outline: none;
    }

    input:hover {
        border-bottom: 1px solid #324354;
    }

    input:focus {
        border-bottom: 1px solid #324354;
        border-color: #324354;   
    }

    .UsuarioNovoDadosBasicInfoContainer {
        display: flex;
        flex: 1;
        justify-content: space-between;

        .UsuarioNovoDadosIDContainer{
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #InputId{
                margin: 1em 0em 0em 0em;
                border: none;
                width: 40px;
            }
        }

        .UsuarioNovoDadosNomeContainer{
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #UsuarioNovoDadosNomeContainerEdit {
                display: flex;
                flex-direction: row;

            }
            
            #InputNome{
                margin: 1em 0em 0em 0em;
                min-width: 600px;
            }
        }

        
        .UsuarioNovoDadosStatusContainer{
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 3em 2em 0em;
            
            #InputStatus{
                margin: 1em 0em 0em 0em;
                width: 40px;
            }
        }
        
    }
    #infoIconNovoUsuario {
        color: rgb(255, 182, 72);
        margin-left: -2.5em;
        margin-right: 1em;
        cursor: pointer;

        #infoIcon {
            font-size: 18px;
            margin-bottom: -10px;
        }
    }

    #checkIconNovoUsuario {
        color: rgb(74, 222, 61);
        margin-left: -2.5em;
        margin-right: 1em;
        cursor: default;

        #checkIcon {
            font-size: 20px;
            margin-bottom: -10px;
        }
    }

    .PermissaoDivDescription {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        margin-bottom: -5px;
        font-weight: bold;
        opacity: 0.5;
    }

    .UsuarioNovoDadosSenhaContainer{
        display: flex;
        flex-direction: column;
        width: min-content;
        margin: 1em 0em 0em 2.5em;

        .SenhaInfoContainer{
            display: flex;
            flex-direction: row;

            #EditIconSenha:hover{
                color: #324354;
                cursor: pointer;
            }
        }

        

        .InputSenhaEditarSenha{
            margin: 1em 0em 0em 0em;
            width: min-content;
            cursor: pointer;
        }

        .InputSenhaEditarSenha:disabled {
            cursor: default;
            outline: none;
        }

        
        .InputSenhaEditarSenha:hover{
            border-bottom: 1px solid #324354;
            transition: 0.4s;
        }
        
        .InputSenhaEditarSenha:hover:disabled {
            cursor: default;
            outline: none;
        }
        
        .InputSenhaEditarSenha:focus{
            outline: none;
            border-bottom: 1px solid #324354;
            transition: 0.4s ease-out;
        }

    }

    .UsuarioNovoDadosConfirmarSenhaContainer{
        display: flex;
        flex-direction: column;
        width: min-content;
        margin: 1em 0em 1em 7em;

        .SenhaInfoContainer{
            display: flex;
            flex-direction: row;

            #EditIconSenha:hover{
                color: #324354;
                cursor: pointer;
            }
        }        

        .InputSenhaEditarSenha{
            margin: 1em 0em 0em 0em;
            width: min-content;
            cursor: pointer;
        }

        .InputSenhaEditarSenha:disabled {
            cursor: default;
            outline: none;
        }

        
        .InputSenhaEditarSenha:hover{
            border-bottom: 1px solid #324354;
            transition: 0.4s;
        }
        
        .InputSenhaEditarSenha:hover:disabled {
            cursor: default;
            outline: none;
        }
        
        .InputSenhaEditarSenha:focus{
            outline: none;
            border-bottom: 1px solid #324354;
            transition: 0.4s ease-out;
        }

    }

    // .disabledBtnEdit {
    //     cursor: default;
    // }

    #TextPermissoesUsuarioNovoDados{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin: 1em 0em;
    }

    .UsuarioNovoDadosButtonsContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 15px;
    }

    .linkBtn {
        text-decoration: none;
    }
}