.modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin: 1.5em 0em 0.5em 0em;
    input {
        margin-right: 0.5em;
    }
}

#TableConteudoProgLancDiario {
    border-collapse: collapse;
    border: 1px solid #85858584;
    margin: 1em 0em 0em 0em;
    width: 100%; 

    th {
        border: 1px solid #85858584;
        // padding: 0.5em; 
    }
    td {
        border: 1px solid #85858584;
        padding: 0.5em; 
    }
}

#confirmar {
    background-color: #2d78a0;
    border: 1px solid #2d78a0;
    border-radius: 5px;
    padding: 0.5em;
    color: white;
}

#confirmar:hover {
    background-color: #3b89b4;
    border: 1px solid #3b89b4;
    border-radius: 5px;
    padding: 0.5em;
    color: white;
    cursor: pointer;
}

#cancelar {
    background-color: #828282;
    border: 1px solid #828282;
    border-radius: 5px;
    padding: 0.5em;
    color: white;
}

#cancelar:hover {
    background-color: #adadad;
    border: 1px solid #adadad;
    border-radius: 5px;
    padding: 0.5em;
    color: white;
    cursor: pointer;
}