.NotasTabelaDashContainer {
    font-size: 12px;
    width: 70vw;
    height: 72vh;
    background-color:white;
    align-self: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 

    .NotasTabelaDashTable{
        display: flex;
        justify-content: center;
        max-height: 87%;
        overflow-y: scroll;
        cursor: default;
        
        table {
            display: table;
            width: 90%;

            thead tr, th{
                padding: 8px 2px 8px 10px;
                color: #333333a1;
                text-align: left;
                font-size: 14px;
                background-color: #fafafa;
            }

            thead th {
                position: sticky;
                top: 0;
                background-color: rgb(255, 255, 255)
            }
    
            tbody {
                overflow: scroll;
            }
            tbody tr td{
                padding: 8px 2px 8px 10px;
                max-height: 10px;
            }

            tbody tr {
                max-height: 10px;
            }

            tbody tr:hover {
                background-color: #ececec;
            }

            tbody tr:nth-child(even) {
                background-color: #bdc8dc30;
            }

            tbody tr:nth-child(even):hover {
                background-color: #e4e4e4;
            }
        }

        #NotasTabelaDashRow:hover {
            background-color: #ececec;
        }

        #IconEditProfessor {
            cursor: pointer;
            color: #324354;
            margin: 2px;
        }

        #IconViewNotas {
            cursor: pointer;
        }
    }

    #ContainerIconSearchLoadingStatusNotas {
        display: flex;
        justify-content: center;
        align-items: center;   
        flex-direction: column;
        opacity: 0.8;   

        img#IdIconSearchLoadingStatus {
            width: 130px;
            height: 130px;
        }
    }

}