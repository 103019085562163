.TabelaGeralContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1em 3em;
    text-align: center;
    border: 1px solid #70707093;
    max-height: min-content;
    overflow-x: hidden;

    #srollInvisibleHeadItem {
        width: 6px;
    }

    .tabelaGeralBodyDataContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;

        #TabelaGeralDashRow {
            display: flex;
            flex: 1;
            flex-direction: row;
            max-height: 30px;

            #itemBodyTabelaGeralDashRow {
                display: flex;
                flex: 1;
                padding: 0.5em;
                border: 1px solid #c5c5c57e;
                border-collapse: collapse;
                justify-content: center;
            }
        }

        #TabelaGeralDashRow:hover {
            background-color: #e0e0e07e;
        }


    }



    table {
        width: 100%;
        height: fit-content;
        border-collapse: collapse;

        thead {
            th {
                background: #f3f3f3;
                color: #333333a1;
                padding: 0.5em;
                font-weight: bold;
                border-bottom: 1px solid #d3d3d3;
                position: sticky;
                top: 0;
            }
        }
    }


}