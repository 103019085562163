#ButtonBackDash {
    background-color: #787878;
    border: 1px solid #787878;
    width: max-content;
    height: max-content;
    margin: 1.5em 0em 1em 1.3em;
    padding: 0.6em 2em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 8px 5px -7px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205,205,205,0.63); 
    align-self: center;
}

#ButtonBackDash:hover {
    cursor: pointer;
    background-color: #7e7e7e;
    border: 1px solid #7c7c7c;
    transition: 0.2s;
    text-decoration: none;
}