.TabelaGeralContainerPermissaoUsuario {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1em 3em;
    text-align: center;
    border: 1px solid #70707093;
    overflow: hidden;
    height: 220px;

    #linhaTabelaPermissaoUsuario {
        display: flex;
        flex: 1;
        flex-direction: column;
        border: 1px solid #c5c5c57e;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        #itemTabelaPermissaoUsuario {
            display: flex;
            flex-direction: row;
            margin-left: 4em;
        }

        div {
            padding: 0.2em;
            
        }

        div#itemTabelaPermissaoUsuario:hover {
            background-color: #e0e0e07e;
        }
    }

    table {
        width: 100%;
        height: max-content;
        border-collapse: collapse;

        thead {
            th {
                background: #f3f3f3;
                color: #333333a1;
                padding: 0.5em;
                font-weight: bold;
                border-bottom: 1px solid #d3d3d3;
                position: sticky;
                top: 10;
            }
        }        
    }
    
}