body {
  margin: 0;
  padding: 0;
}
body .SideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 250px;
  background-color: #2F4050;
  background-image: url("../../images/Edu-4.png");
  background-blend-mode: luminosity;
  background-size: cover;
  overflow: hidden;
  margin-right: 0px;
}
body .SideBar header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  color: white;
}
body .SideBar header #Escola {
  font-weight: bold;
  margin: 0.6em 0em 0em 0em;
  text-transform: capitalize;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
body .SideBar header #Cargo {
  font-weight: lighter;
  font-size: 12px;
  opacity: 0.7;
}
body .SideBar header hr {
  margin-top: 10px;
  margin-bottom: -10px;
  width: 65%;
  opacity: 0.4;
}
body .SideBar .SideBarList {
  padding: 0;
  width: 100%;
  overflow: hidden;
}
body .SideBar .SideBarList .row {
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 12px;
}
body .SideBar .SideBarList .row:hover {
  cursor: pointer;
  background-color: rgba(31, 41, 51, 0.7254901961);
  border-left: 4px solid #4BB4FF;
  transition: 0.3s;
}
body .SideBar .SideBarList #active {
  background-color: rgba(16, 32, 49, 0.5568627451);
}
body .SideBar .SideBarList #icon {
  flex: 30%;
  display: grid;
  place-content: center;
}
body .SideBar .SideBarList #title {
  flex: 70%;
}
body .SideBar #SairButtonSideBarAdmin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  background-color: red;
  background: none;
  border: none;
  color: #fafafa;
  outline: none;
  font-weight: bold;
  margin-left: 10px;
  padding: 2em 20px;
  font-size: 12px;
  cursor: pointer;
}/*# sourceMappingURL=SideBar.css.map */