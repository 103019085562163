.CardDashConteinerGreen {
  background-color: rgb(40, 227, 80);
  width: 200px;
  height: 100px;
  border-radius: 15px;
  background: rgb(40, 227, 80);
  background: linear-gradient(90deg, rgb(39, 168, 68) 0%, rgb(40, 227, 80) 100%);
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.CardDashConteinerGreen .CardDashInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.CardDashConteinerGreen .CardDashInfo #NumberCardDash {
  font-size: 52px;
}
.CardDashConteinerGreen .CardDashInfo #GroupIcon {
  font-size: 70px;
  color: white;
  opacity: 0.5;
}
.CardDashConteinerGreen #DescriptionDashCard {
  color: white;
  opacity: 0.9;
  margin-left: 1.6em;
  margin-bottom: 1em;
}/*# sourceMappingURL=CardGreen.css.map */