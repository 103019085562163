.NotasTabelaGeralContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 3em;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.5764705882);
  max-height: -moz-min-content;
  max-height: min-content;
  overflow-x: hidden;
}
.NotasTabelaGeralContainer #srollInvisibleHeadItem {
  width: 6px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer :nth-child(2) {
  display: flex;
  min-width: 50%;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 30px;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow #itemBodyNotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  padding: 0.5em;
  border: 1px solid rgba(197, 197, 197, 0.4941176471);
  border-collapse: collapse;
  justify-content: center;
}
.NotasTabelaGeralContainer .NotastabelaGeralBodyDataContainer #NotasTabelaGeralDashRow:hover {
  background-color: rgba(224, 224, 224, 0.4941176471);
}
.NotasTabelaGeralContainer :nth-child(2) {
  display: flex;
  min-width: 50%;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable {
  display: flex;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-collapse: collapse;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable #itemHeaderNotasTabelaGeralDashRow {
  display: flex;
  flex: 1;
  padding: 0.5em;
  border-collapse: collapse;
  justify-content: center;
}
.NotasTabelaGeralContainer .NotasTabelaGeralTable .NotasTabelaGeralHeaderTable div {
  background: #f3f3f3;
  color: rgba(51, 51, 51, 0.631372549);
  padding: 0.5em;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d3;
  position: sticky;
  top: 0;
}
.NotasTabelaGeralContainer .NotasDadosButtonsContainer {
  margin-top: -15px;
  background-color: red;
}/*# sourceMappingURL=NotasTabelaGeral.css.map */