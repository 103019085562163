@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    font-family: 'Poppins', arial;
    background-color: #fafafa;
    margin: 0;
    padding: 0;
    
    a {
        text-decoration: none;
        cursor: pointer;
    }
}

#AppHeader{
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #3080ac;
    width: 100%;
    height: 4.5em;
    color: #fefefe;
    padding: 0em 0em 0em 2em;

    a {
        color: #fefefe;
        text-decoration: none;
    }

    h1 {
        color: #fafafa;
    }

    .menu {
        display: flex;
        width: 30vw;
        float: right;
        justify-content: space-between;
        align-items: center;
        padding: 0em 0em 0em 20vw;
        letter-spacing: .1em;

        .menu-notas {
            margin-right: 3em;
            margin-left: 2em;
            //min-width: 5em;
            opacity: 1;
            
            a {
                cursor: pointer;
            }            
        }

        .item-menu::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: #fff;
            transition: width .3s;
        }

        .item-menu:hover::after {
            width: 100%;
            transition: width .3s;
        }

        .menu-diario {
            //margin-right: 2em;
            margin-left: 2em;
            padding-right: 1.5em;
        }

        .menu-inicio {
            //margin-right: 2em;
            margin-left: 2em;
            padding-right: 2em;
        }

        .menu-pedagogico{
            // opacity: 0.5;
            position: static;
            // a {
            //     cursor: default;
            // }
        }
    }

    .button-sair {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        max-width: 5em;
        margin: 0em 0em 0em 30vw;
        background-color: #3080ac;
        p {
            margin-right: 8px;
        }
    } 

}
@media ( max-width:1084px){
    header {
        width: auto;
        .menu {
            padding: 0em 0em 0em 8em;
        }

        .button-sair {
            margin: 0em 0em 0em 28vw;
        }
    }
}

@media ( max-width:928px){
    header {
        .menu {
            padding: 0em 0em 0em 2em;
        }
        // .menu-inicio {}
        // .menu-diario {}
        // .menu-notas {}
        .menu-pedagogico {
            display: none;
        }

        .button-sair {
            // font-size: 16px;
            margin: 0em 0vw 0em 0vw;
        }
    }
}

@media ( max-width:771px){
    header#AppHeader {
        .menu {
            padding: 0em 0em 0em 1em;

            .menu-inicio {
                margin: 0em 1em 0em 3em;
                padding: 0em;
            }
            .menu-diario {
                margin: 0em 1em;
                padding: 0em;
            }
            .menu-notas {
                margin: 0em 1em;
                padding: 0em;
            }
        }

        .button-sair {
            font-size: 16px;
            margin: 0em 15vw 0em 24vw;
        }
    }
}

@media ( max-width:649px){
    header#AppHeader {
        h1 {
            display: none;
        }
        display: flex;
        justify-content: space-between;
        padding: 0;
        .menu { 
            flex: 2;    
            .menu-inicio {
                margin: 0em 1em 0em 3em;
                padding: 0em;
            }
            .menu-diario {
                margin: 0em 1em;
                padding: 0em;
            }
            .menu-notas {
                margin: 0em 1em;
                padding: 0em;
            }
        }

        .button-sair {
            padding: 0;
        }
    }
}

@media ( max-width:649px){
    header#AppHeader {
        h1 {
            display: none;
        }
        font-size: 16px;
        .menu { 
            flex: 2;    
            .menu-inicio {
                margin: 0em .5em 0em 1em;
                padding: 0em;
            }
            .menu-diario {
                margin: 0em .5em;
                padding: 0em;
            }
            .menu-notas {
                margin: 0em .5em;
                padding: 0em;
            }
        }

        .button-sair {
            font-size: 14px;
            margin: 0em 10vw 0em 25vw;
        }
    }
}

@media ( max-width:600px){
    header#AppHeader {
        
        font-size: 16px;

        h1 {
            display: none;
        }
        
        .menu { 
            flex: 2;
            margin-left: 0px;    
            padding: 0em 0em 0em 0em;
            .menu-inicio {
                margin: 0em .5em 0em 1em;
                padding: 0em;
            }
            .menu-diario {
                margin: 0em .5em;
                padding: 0em;
            }
            .menu-notas {
                margin: 0em .5em;
                padding: 0em;
            }
        }

        .button-sair {
            font-size: 16px;
            margin: 0em 2vw 0em 22vw;
        }
    }
}