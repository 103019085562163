// .css-1g86id8-MuiTreeItem-content {
//     width: auto;
// }

.css-1g86id8-MuiTreeItem-content div.MuiTreeItem-label {
    padding-left: 0em;
    padding: 0.4em 0em;
}

.FecharFaltasGerenciarAdminContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100vw;
    height: 100vh;

    div#renderTreeDisciplinas {
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;

        .renderTreeDisciplinasCheckbox {
            margin-top: 0px; 
            margin-right: -2px;
        }
    }

    div#renderTreeTurmas {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .renderTreeTurmasCheckbox {
            margin-right: -8px
        }
    }

    .FecharFaltasPageRigthSide {
        display: flex;
        flex: 1;
        flex-direction: column;

        #FecharFaltasTitlePage {
            display: flex;
            font-size: 10px;
            color: #534A3C;
            font-weight: bold;
            opacity: 0.6;
            letter-spacing: 5px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 50px;
        }

        .FecharFaltasBodyPainelContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-self: center;
            justify-self: center;
            justify-content: space-around;
            align-items: center;
            height: 30em;
            width: 80%;
            max-width: 80em;
            margin-bottom: 5em;
            border-radius: 20px;
            border-top: 40px solid #324354;
            background-color: white;
            box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 

            h3 {
                color: #324354;
                margin: 0;
                margin-top: 1em;
                margin-bottom: -1em;
            }

            p {
                opacity: 0.5;
            }

            #LinkButtonVoltarFecharNotas {
                text-decoration: none;
            }

            .TreeViewFecharFaltasContainer {
                display: flex;
                flex: 1;
                width: 95%;
                height: 100%;
                max-height: 20em;
                overflow: hidden;
                border: 1px solid #32435444;
                border-radius: 10px;
            }
        }

    }

    .FecharFaltasButtonsContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        max-height: 100px;
        justify-content: space-between;
        align-items: center;
        padding: 0em 2em;

        button {
            margin-top: 10px;
            max-height: 50px;
            height: 45px;
            border: 1px solid #324354;
            background-color: #324354;
            border-radius: 5px;
            margin-right: 5em;
            font-weight: bold;
            color: white;
            font-family: 'Poppins', arial;
            padding: 0.5em 2.5em;
            box-shadow: 0px 8px 5px -7px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205,205,205,0.63); 
            cursor: pointer;
        }

        button:hover {
            border: 1px solid #49637d;
            background-color: #49637d;
        }
    }

}