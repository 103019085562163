
//INTERNAL CLASS
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-out;
    
}

//INTERNAL CLASS
.ReactModal__Overlay--after-open {
    opacity: 1;

}

//INTERNAL CLASS
.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-content-block-button {
    display: flex;
    flex-direction: column;
    min-width: 30em;
    max-width: 30em;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
    max-height: 70vh;
    text-transform: uppercase;
    padding-bottom: 2.5em;
    cursor: default;

    h2 {
        text-align: center;
        color: #313131;
        opacity: 0.9;
        max-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        font-size: 14px;
        text-align: center;
        color: #313131;
        opacity: 0.7;
        max-height: 40px;
        margin: -12px 0px 23px 0px; 
    }

    #ButtonModalStudentContainer {
        display: flex;
        // flex: 1;
        max-height: 3em;
        flex-direction: row;
        justify-content: space-between;   
    }

    #ButtonConfirmDash {
        padding: 0.8em 1.5em;
        margin-right: 1.6em;
    }
}

//INTERNAL CLASS
.modal-overlay {
    background-color: rgba(226, 226, 226, 0.6);
    backdrop-filter: blur(6px);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.DiarioDadosAdmin{
    display: flex;
    justify-content: center;

    .DiarioDadosContainer {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        width: 70vw;
        height: 80vh;
        margin-bottom: 3em;
        background-color: white;
        margin-top: -30px;
        padding-bottom: 20px;
        align-self: center;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
        cursor: default;
    
        
    
        
    
        .modal-content-block-button {
            display: flex;
            flex-direction: column;
            width: 32em;
            max-width: 32em;
            background-color: white;
            padding: 20px;
            border-radius: 20px;
            outline: none;
            box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
            max-height: 70vh;
            text-transform: uppercase;
            padding-bottom: 3em;
            cursor: default;
    
            h2 {
                text-align: center;
                color: #313131;
                opacity: 0.9;
                max-height: 40px;
                margin-bottom: 0;
                margin-top: 0;
            }
    
            p {
                font-size: 14px;
                text-align: center;
                color: #313131;
                opacity: 0.7;
                max-height: 40px;
                margin: -12px 0px 23px 0px;
            }
    
            #ButtonModalStudentContainer {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: space-between;
            }
    
            #ButtonConfirmDash {
                padding: 0.8em 1.5em;
                margin-right: 1.6em;
            }
        }
    
        .DiarioDadosDashBarraPesquisa {
            display: flex;
            background-color: #324354;
            color: white;
            padding: 0.5em;
            height: 28px;
            min-height: 28px;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
        }
    
        .TextDescricao {
            font-weight: bold;
            color: #3333339c;
        }
    
        .TextAulas {
            font-weight: bold;
            color: #3333339c;
            max-width: 200px;
        }
    
        .TextDescricao {
            font-weight: bold;
            color: #3333339c;
        }
    
        input {
            background-color: white;
            border: none;
            border-bottom: 1px solid #bababade;
            max-width: fit-content;
        }
    
        .DiarioDadosBasicInfoContainer {
            display: flex;
            flex: 1;
            justify-content: space-between;
            max-height: 80px;
    
            .DiarioDadosDataContainer {
                display: flex;
                flex-direction: column;
                background-color: white;
                margin: 1em 0em 0em 2.5em;
    
                #InputId {
                    background-color: #31313131;
                    color: #313131bb;
                    border-radius: 10px;
                    padding: 2px 10px;
                    margin-right: -5px;
                    margin: 1em 0em 0em 0em;
                    border: none;
                    width: 70px;
                    text-align: center;
                }
            }
    
            .DiarioDadosCursoContainer {
                display: flex;
                flex: 1;
                flex-direction: column;
                background-color: white;
                margin: 1em 0em 0em 2.5em;
    
                #InputNome {
                    margin: 1em 0em 0em 0em;
                    min-width: 95%;
                }
            }
    
            .DiarioDadosTurmaContainer {
                display: flex;
                flex-direction: column;
                background-color: white;
                margin: 1em 3em 2em 0em;
    
                #InputStatus {
                    margin: 1em 0em 0em 0em;
                    width: 40px;
                }
            }
    
    
        }
    
        #DescricaoDiarioTitle {
            color: #313131;
            font-weight: bold;
        }
    
        .DescricaoDiarioContainer {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 4em;
    
            textarea {
                display: flex;
                flex: 1;
                border-radius: 10px;
                overflow-clip-margin: none;
                padding: 10px;
                resize: none;
                border: 1px solid #32435444;
            }
    
            #ConteudosDiario {
                height: 5vh;
            }
    
            #AtividadeAulaDiario {
                height: 40px;
            }
    
            #AtividadeCasaDiario {
                height: 40px;
                margin-bottom: 2px;
            }
        }
    
        .DiarioDadosBasicInfoContainerRow2 {
            display: flex;
            flex: 1;
            flex-direction: row;
            max-height: 70px;
            max-width: 40%;
    
            .DiarioDadosDisciplinaContainer {
                display: flex;
                flex-direction: column;
                background-color: white;
                margin: 0.5em -20.5em 0em 2.5em;
    
                #InputDisciplina {
                    margin: 0.5em 0em 0em 0em;
                    min-width: 80px;
                }
            }
    
            .DiarioDadosAulasContainer {
                display: flex;
                flex-direction: column;
                background-color: white;
                margin: 0.5em 0em 1em 0em;
                min-width: fit-content;
    
                #InputAulas {
                    margin: 0.5em 0em 0em 0em;
                    width: 40px;
                }
            }
    
            .DiarioDadosBloquadoContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: -1.2em;
                margin-left: 8em;
    
                .BloqueadoContainer {
                    margin-top: 0.5em;
                }
            }
        }
    
        .DiarioDadosDisciplinaContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-width: 40em;
            margin: 0.5em 0em 0em 2.5em;
    
            .DisciplinaInfoContainer,
            .AulasInfoContainer {
                display: flex;
                flex-direction: row;
    
                #EditIconDisciplina:hover {
                    // color: #324354;
                    cursor: default;
                }
            }
    
            #InputDisciplina {
                display: flex;
                flex: 1;
                margin: 1em 0em 0em 0em;
                min-width: 300px;
                cursor: default;
            }
    
            #InputDisciplina:hover {
                // border-bottom: 1px solid #324354;
                cursor: default;
            }
    
            #InputDisciplina:focus {
                outline: none;
            }
        }
    
        #TextPermissoesDiarioDados {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            margin: 0em 0em;
            max-height: 15px;
        }
    
        .descricaoDiario {
            font-size: 10px;
            opacity: 0.8;
            margin-bottom: 4px;
        }
    
        .DiarioDadosButtonsContainer {
            display: flex;
            flex: 1;
            flex-direction: row;
            max-height: 60px;
            align-items: flex-end;
    
            div#buttonBackDiarioDados {
                #ButtonBackDash {
                    background-color: #324354;
                }
    
                #ButtonConfirmDash {
                    margin: 1.4em 0em 0.9em 1.3em;
                }
            }
    
            #ButtonModalStudent {
                margin: 0px 0px 11px 20px;
            }
    
            #ButtonBack {
                margin-left: 20px;
                text-decoration: none;
            }
    
            #ButtonDeleteContainerDiarioDados {
                display: flex;
                flex: 1;
                justify-content: right;
                align-items: center;
                margin-right: 4em;
            }
        }
    }
}

#TitleModalDiarioDados {
    display: flex;
    justify-content: center;
    align-items: center;

    #WarningIconDiarioDados {
        color: rgb(231, 129, 39);
        font-size: larger;
        margin-right: 0.5em;
    }
}