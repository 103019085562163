#DiarioGeneralAdminContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100vh;
    width: 100vw;

    .DiarioAdminContainer {
        display: flex;
        flex: 1;
        flex-direction: column;

        .DiarioSearchContainer {
            display: flex;
            align-items: center;
            align-self: center;
            background-color: #324354;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: 70vw;
            height: 50px;
            margin-top: -30px;
            box-shadow: 0px 10px 13px -7px rgba(219, 219, 219, 0.536), 7px 15px 16px -6px rgba(230, 230, 230, 0.629);

            select {
                color: #324354e0;
                height: fit-content;
                margin: 20px 0px 20px 2em;
                border: 1px solid #aeaeae9f;
                border-radius: 5px;
                outline: none;
                padding: 4px;
                max-width: 15em;
            }

            #labelInicioDiarioData {
                color: white;
                margin-left: 15px;
                margin-right: 6px;
            }

            #labelFimDiarioData {
                margin-left: 15px;
                margin-right: 6px;
                color: white;
            }

            input {
                border: 1px solid #aeaeae9f;
                border-radius: 5px;
                outline: none;
                padding: 3px;
                color: #324354;
            }

        }

        #DiarioTitlePage {
            font-size: 10px;
            color: #534A3C;
            font-weight: bold;
            opacity: 0.6;
            letter-spacing: 5px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }
}