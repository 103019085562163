@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    font-family: 'Poppins', arial;
    background-color: #fafafa;
    margin: 0;
    padding: 0;


    div.container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;

        header{
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: #3080ac;
            width: 100%;
            height: 4em;
            color: #fefefe;
            padding: 0em 0em 0em 2em;
        
            .menu {
                display: flex;
                width: 30vw;
                float: right;
                justify-content: space-between;
                align-items: center;
                padding: 0em 0em 0em 20vw;
                letter-spacing: .1em;
            }
        
            .button-sair {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                margin: 0em 0em 0em 30vw;
            
                p {
                    margin-right: 8px;
                }
            
            } 
        }

        .title {
            display: flex;
            flex-direction: column;
            margin: .5em 0em .5em 5em;

            p.title-page {
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 0px;
                color: #2d78a0;
            }

            p.subtitle-page {
                margin-top: 0px;
                color: #333333;
                opacity: 0.8;
            }
        }

        section{
            width: 100%;

            .info-bar{
                display: flex;
                width: 100%;
                justify-content: initial;
                align-items: center;
                padding-right: 2em;
            }
    
        }

        .conteudo {
            min-width: 100%; 
        }

        
        div.container-informativo {
            display: none;
            flex-direction: column;
            margin: 0em 0em 0em 5em;
            background-color: #e4e4e4;
            width: 25em;
            height: 5vh;
            padding: 1em;
            border-radius: .5em;
            margin-left: 100vh;
            align-items: flex-end;
            padding-bottom: 1.8em;

            p.informativo-title {
                
                font-size: 12px;
                letter-spacing: .1em;
                font-weight: bold;
                
                color: #916f00;
                margin: 0em 0em -.5em 0em;
            }

            p.informativo-desc {
                font-size: 11px;
                color: #333333;
                opacity: 0.7;
                text-align: justify;
            }

        }

        div.container-form {
            display: flex;
            flex-direction: column;
            margin: 2em 60em 0em 6em;
            min-width: 45em;
            font-weight: bold;
            color: #333333;
            font-size: 14px;

            label {
                color: #333333;

            }

            #consultarConteudoProgContainer {
                display: flex;
                flex-direction: row;
                margin: 0;
                padding: 0;
                width: calc(100% - 5em);
                justify-content: space-between;
                // background: #c7484883;
                margin-bottom: 0.2em;
                align-items: flex-end;
            }

            #consultarConteudoProgBtn {
                margin: 0;
                background-color: #2d78a0;
                color: white;
                // width: 2em;
                height: 1.8em;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 2px;
                padding-right: -1px;
            }
            
            label#TotalAlunosDiarioLancar{
                display: flex;
                justify-content: flex-end;
                font-weight: normal;
                color: #313131;
                opacity: 0.5;
                margin-right: 3.4em;
                margin-top: -26px;
                margin-bottom: 18px;
                font-size: 14px;
            }

            label#TotalTextoDiarioLancar{
                display: flex;
                justify-content: flex-end;
                font-weight: normal;
                color: #313131;
                opacity: 0.5;
                margin-right: 4.9em;
                margin-top: -10px;
                font-size: 10px;
            }

            input[type=checkbox] {
                width: 1.1em;
                height: 1.1em;
                margin: 0em 2px .5em .5em;
            }

            #conteudo {
                margin-top: 0;
                min-width: 43em;
                max-width: 43em;
                margin-right: 0.5em; 
            }

            #conteudoContainer {
                display: flex;
                flex-direction: row;
                margin: 0;
                padding: 0;
            }

            #atividade_aula {
                min-width: 43.3em;
                margin-right: 0.5em;
            }

            #atividade_casa {
                min-width: 43.3em;
                margin-right: 0.5em;
            }

            #colar {
                margin: 0;
                background: url('../../../images/edit-white.svg') no-repeat 4px;
                background-size: 20px;
                background-color: #2d78a0;
                color: white;
                width: 2em;
                height: 1.9em;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 2px;
                padding-right: -1px;
            }

            #colar:hover {
                background-color: #3080ac;
            }

            input {
                margin-top: .4em;
                margin-bottom: 1em;
                border: 1px solid rgba(0,0,0,0.2);
                padding: .3em;
            }

            select {
                margin-top: .2em;
                margin-bottom: .8em;
                border: 1px solid rgba(0,0,0,0.2);
                padding: .3em;
                max-width: 43em;
            }
            select:focus{
                border: 1px solid #3797ca;
                max-width: 43em;
            }

            select#turma {
                width: 4em;
            }

            input[type="text"] {
                padding: .3em;
                border: 1px solid rgba(0,0,0,0.2);
            }
            input[type="text"]:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3aafeea8;
            }

            input[type="text"]::placeholder{
                color: #487892a8;
            }

            input[type="date"] { 
                width: 10em;
            }
            input[type="date"]:focus { 
                border: 1px solid #3081aca8;
                outline: 1px solid #3081aca8;
            }

            input[type="number"] { 
                width: 3em;
                padding: .3em;
            }
            input[type="number"]:focus { 
                border: 1px solid #3081aca8;
                outline: 1px solid #3081aca8;
            }

            input[type="submit"] {
                margin-bottom: 7em;
                width: 10em;
                padding: .7em;
                border-radius: 5px;
                font-weight: bold;
                font-size: 14px;
                color: white;
            }

            #confirmar {
                background-color: #2d78a0;
            }

            #confirmar:hover {
                background-color: #3293c7;
            }

            #confirmar:disabled{
                background-color: #5c7988;
                cursor: wait;
            }

            #confirmar:disabled:hover{
                background-color: #5c7988;
                cursor: wait;
            }

            input[type="submit"]:hover {
                background-color: #3080ac;
                cursor: pointer;
            }

            .aulasContainer {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;

                .aulaCheckContainer {
                    display: flex;
                    align-items: center;
                    padding-bottom: 7px;

                    .editarCheckText {
                        margin: 0 0 0 3px;
                        padding: 0;
                        color: #313131;
                        font-weight: normal;
                        opacity: 0.8;
                    }

                    #aulasEdit {
                        margin: 0 0 0 1.5em;
                        size: 25px;
                    }
                }
            }

            

            #deletar {
                background-color: #bd3434;
                cursor: pointer;
            }

            #deletar:hover{
                background-color: #ce3434; 
            }

            #deletar:disabled {
                cursor: default;
                background-color: #5c7988;
                cursor: default;
            }            

            textarea {
                border: 1px solid rgba(0,0,0,0.2);
                resize: none;
                margin-bottom: 1em;
                max-width: 41.5em;
            }
            textarea:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3aafeea8;
            }

            textarea::placeholder{
                color: #487892c5;
            }

            details {
                margin: 0em 0em 2em 0em;
                max-width: 41.8em;
                
                summary {
                    border-radius: .3em;
                    padding: .3em 0em .3em .8em;
                    background-color: #2d78a0;
                    color: white;
                    outline: none;
                    margin-bottom: -1px;
                    cursor: pointer;
                }

                summary:hover {
                    background-color: #3080ac;
                }

                table {
                    border-bottom: 2px solid #2d78a0;
                    border-right: 2px solid #2d78a0;
                    border-left: 2px solid #2d78a0;
                    border-spacing: 0px;
                    width: 100%;
                    

                    tr, td{
                        border: 1px solid #adadad8a;
                        padding-left: .5em;

                        .RegistroFaltas{
                            color: #888888;
                            opacity: 0.8;
                            text-align: center;
                            font-weight: normal;
                        }
                    }

                    tr:nth-child(even){
                        background-color: #f2f2f2;
                    }

                    th {
                        border-bottom: 1px solid #adadad8a;
                        border-right: 1px solid #adadad8a;
                        border-left: 1px solid #adadad8a;
                        padding: .5em 0em .5em 0em;
                        cursor: default;
                    }
                    th[title]:hover::after {
                        background-color: #2d78a0;
                        color: white;
                    }
                    td.checkbox {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-self: center;
                        padding: 2px 5px;
                    }

                    tr td {
                        text-align: left;
                        padding-left: 8px;
                    }
                }
            }

            .loadingLançar {
                background-image: url('../../../images/Rolling-1s-200px.svg');
                background-repeat: no-repeat;
                //background-position: 2px 3px;
                background-size: contain;
                padding-right: .5em;
            }

        }
        
    }
    
}

@media (max-width: 769px){
    body {
        margin: 0;
        padding: 0;
    }
    div.container {
        section #infoBarDiario{
            width: auto;
            padding-right: 0em;

            .title {
                margin: 0.5em 0em 0em 2em;
            }
        }

        #colar {
            display: none;
        }

        div#containerFormDiario{
            margin: 1em 0em 0em 0em;
            padding: 0em 0em 0em 2em;
            min-width: 95vw;    
            max-width: 95vw;
            

            label {
                width: 50vw;
            }

            #observacao {
                color: #755020;
                opacity: 0.8;
                font-weight: normal;
                margin-bottom: -0.5em;
                margin-top: 1em;
            }

            #curso {
                
                max-width: 84vw;
            }

            #disciplina {
                max-width: 84vw;
            }

            #conteudo {
                min-width: 84vw;
            }

            #atividade_aula {
                min-width: 85vw;
            }

            #atividade_casa {
                min-width: 85vw;
            }

            #data {
                font-size: 14px;
            }

            details {
                min-width: 90vw;
                width: 90vw;
                max-width: 90vw;
                font-size: 12px;

                table {
                    #faltasTitleGridDiario{ 
                        min-width: 55px;
                    }  
    
                    #checkBoxContainerDiario {
                        margin: 0;
                        padding: 0;
                        text-align: center;                        
                    }
                }


                #matriculaTitleGridDiario {
                    display: none;
                }

                #matriculaFieldGridDiario {
                    display: none;
                }

                #nomeFieldTitleDiario {
                    width: min-content;
                    max-width: min-content;
                }
                #nomeFieldGridDiario {
                    width: min-content;
                    max-width: min-content;
                    font-weight: normal;
                }
            }
        }
    }
}

@media (max-width: 563px){
    div.container {
        section {
            #infoBarDiario {
                .title {
                    margin: 0.5em 0em 0em 1em;
                }
            }
        }

        
        div#containerFormDiario {
            padding: 0em 0em 0em 1em;
            
            details {
                #nomeFieldGridDiario {
                    font-size: 10px;
                    
                }
                input[type=checkbox] {
                    width: 1em;
                    height: 1em;
                    margin: 0em 2px .3em .3em;
                }
            }
        }

        
    }
}

@media (max-width: 378px){
    div#containerFormDiario {        
        details {
            summary {
                #faltasTitleGridDiario{ 
                    width: 100%;
                }

               
            }
            #nomeFieldGridDiario {
                font-size: 10px;
                
            }

            #lancadosTitleGridDiario {
                display: none;
            }

            input[type=checkbox] {
                width: 1em;
                height: 1em;
                margin: 0em 2px .3em .3em;
            }
        }
    }
}

@media (max-width: 338px){
    div.container {
        div#containerFormDiario {      
            label {
                font-size: 12px;
            }  

            input {
                font-size: 14px;
            }

            #conteudo {
                min-width: 80vw;
            }

            #atividade_aula {
                min-width: 81vw;
            }

            #atividade_casa {
                min-width: 81vw;
            }

            #confirmar {
                font-size: 14px;
                margin: 0em 1.7em 0em 0em;
                padding: .5em 1em;
                width: fit-content;
            }

            #deletar {
                font-size: 14px;
                margin: 0em 0em 4em 0em;
                padding: .5em 1em;
                width: fit-content;
            }

            #colar {
                margin-left: 0em;
                width: 24px;
                height: 24px;
                background-size: 18px;
            }

            details {
                table {
                    #faltasTitleGridDiario{ 
                        min-width: 55px;
                    }  

                    #checkBoxContainerDiario {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        
                        
                    }
                }
            }
        }
    }
}