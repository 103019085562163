#EditarUsuarioGeneralContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
#EditarUsuarioGeneralContainer .EditarUsuarioContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#EditarUsuarioGeneralContainer .EditarUsuarioContainer #editarUsuarioTitlePage {
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}/*# sourceMappingURL=EditarUsuario.css.map */