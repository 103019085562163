.ButtonDisabledDash {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #7a7a7a;
    border: 1px solid #7a7a7a;
    width: max-content;
    height: max-content;
    margin: 1.5em 0em 1em 3em;
    padding: 0.6em 2em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 8px 5px -7px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205,205,205,0.63); 

    #loadingIconDisabled {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        margin-top: -10px;
        margin-left: -0.5em;
    }
}

.cursorIsLoadingActivated {
    cursor: wait;
    transition: 0.2s;
}

.loadingIconActivated {
    padding: 0.6em 1em;
}