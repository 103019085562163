#ProfessoresGeneralDashContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}
#ProfessoresGeneralDashContainer .ProfessoresDashContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#ProfessoresGeneralDashContainer .ProfessoresDashContainer #professoresTitlePage {
  font-size: 10px;
  color: #534A3C;
  font-weight: bold;
  opacity: 0.6;
  letter-spacing: 5px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}/*# sourceMappingURL=Exportar.css.map */