#ContainerModalFecharFaltas {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    // background-color: #f1f1f1;
}

//INTERNAL CLASS
.modal-overlay {
    background-color: rgba(226, 226, 226, 0.6);
    backdrop-filter: blur(6px);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

//INTERNAL CLASS
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-out;
}

//INTERNAL CLASS
.ReactModal__Overlay--after-open {
    opacity: 1;
}

//INTERNAL CLASS
.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1600px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
    height: 90vh;
    text-transform: uppercase;
    cursor: default;

    h2 {
        text-align: center;
        color: #313131;
        opacity: 0.9;
        max-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        font-size: 14px;
        text-align: center;
        color: #313131;
        opacity: 0.7;
        max-height: 40px;
        margin: -12px 0px 0px 0px; 
    }

    #ButtonModalFecharFaltasContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0em 2em;

        #AlunosQtdInfoFecharFaltasModal {
            margin-top: 10px;
            margin-right: 20px;
            font-size: 14px;
            color: #313131;
            opacity: 0.5;
        }
    }
}

#ButtonModalFecharOpen {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 12px;
    background-color: #324354;
    border: 1px solid #324354;
    width: max-content;
    min-width: 100px;
    height: max-content;
    // margin: 1.5em 0em 0.5em 2em;
    padding: 0.9em 2.5em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
    
    #CloseIconModalFecharFaltas {
        padding-right: 5px;
        font-size: 20px;
        margin: 0;   
    }
}

#ButtonModalFecharConfirmar {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 12px;
    background-color: #324354;
    border: 1px solid #324354;
    width: max-content;
    min-width: 100px;
    height: max-content;
    // margin: 1.5em 0em 0.5em 2em;
    padding: 1em 2.5em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
}


#ButtonModalFecharFaltas {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 12px;
    background-color: #636363;
    border: 1px solid #636363;
    width: max-content;
    min-width: 100px;
    height: max-content;
    // margin: 1.5em 0em 0.5em 2em;
    padding: 0.9em 1em;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
    
    #CloseIconModalFecharFaltas {
        padding-right: 5px;
        font-size: 20px;
        margin: 0;   
    }
}

#tabelaAlunosModal {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid rgba(189, 200, 220, 0.3);
    border-radius: 10px;
    padding: 0px 0px 0px 0px;
    max-height: 100%;
    overflow-y: hidden;
    font-size: 14px;

    #tabelaAlunosModalHeaderTitles {
        display: flex;
        flex: 1;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(189, 200, 220, 0.18);

        div:nth-child(2) {
            padding-left: 1em;
        }

        div:nth-child(3) {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            min-width: 40px;
            text-align: center;
            // border-right: none;
            margin-right: 15px;
            padding-right: 15px;
        }

        div {
            padding: 8px 10px 8px 10px;
            border-right: 1px solid rgba(189, 200, 220, 0.5);
            ;
        }
    }

    #tabelaAlunosModalHeaderTitles:nth-child(odd) {
        background-color: rgba(189, 200, 220, 0.18);
        border: 1px solid rgba(189, 200, 220, 0.18);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    #tabelaAlunosModalBodyItems {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 100%;
        overflow-y: scroll;
    }

    #tabelaAlunosModalRow {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 6px 20px;
    }

    #tabelaAlunosModalRow:nth-child(odd) {
        background-color: rgba(189, 200, 220, 0.18);
    }

    #tabelaAlunosModalRow:hover {
        background-color: rgba(189, 200, 220, 0.3);
    }

    #bodyItemAlunosModal:nth-child(1) {
        width: 90px;
        max-width: 90px;
    }

    #bodyItemAlunosModal:nth-child(2) {
        width: 470px;
        max-width: 470px;
        
        div {
            background-color: red;
        }
    }

    #bodyItemAlunosModal:nth-child(3) {
        width: 50px;
        max-width: 50px;
        text-align: center;
    }
}

button:hover {
    background-color: #3d5368;
    cursor: pointer;
    border: 1px solid #3d5368;
    transition: 0.2s;
    text-decoration: none;

}
