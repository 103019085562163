#NotasGeneralContainerAdmin {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100vh;
    width: 100vw;

    .NotasContainerAdmin {
        display: flex;
        flex: 1;
        flex-direction: column;

        .NotasSearchContainer {
            display: flex;
            align-items: center;
            align-self: center;
            background-color: #324354;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: 70vw;
            height: 50px;
            margin-top: -30px;
            box-shadow: 0px 10px 13px -7px rgba(219, 219, 219, 0.536), 7px 15px 16px -6px rgba(230, 230, 230, 0.629);

            select {
                color: #324354e0;
                height: fit-content;
                margin: 20px 0px 20px 2em;
                border: 1px solid #aeaeae9f;
                border-radius: 5px;
                outline: none;
                padding: 4px;
                max-width: 20em;
            }

            #labelInicioNotasData {
                color: white;
                margin-left: 15px;
                margin-right: 6px;
            }

            #labelFimNotasData {
                margin-left: 15px;
                margin-right: 6px;
                color: white;
            }

            input {
                border: 1px solid #aeaeae9f;
                border-radius: 5px;
                outline: none;
                padding: 3px;
                color: #324354;
            }

        }

        .button-Info-Container {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-content: space-around;
            align-self: center;
            width: 90%;
            margin-top: -1em;
            padding: 0;

            #registros-Notas-Info {
                display: flex;
                flex: 1;
                justify-content: flex-end;
                margin: -2.8em 0em 0px 0em;
                padding-right: 10%;
                min-width: fit-content;
                font-size: small;
                opacity: 0.6;
                color: #313131;     
            }
        }

        #NotasTitlePage {
            font-size: 10px;
            color: #534A3C;
            font-weight: bold;
            opacity: 0.6;
            letter-spacing: 5px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }
}