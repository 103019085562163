.UsuarioNovoDadosContainer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 70vw;
  height: 72vh;
  background-color: white;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: default;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosDashContainer {
  display: flex;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainerGeral {
  display: flex;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosDashBarraPesquisa {
  display: flex;
  background-color: #324354;
  color: white;
  padding: 0.5em;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.UsuarioNovoDadosContainer .TextDescricao {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6117647059);
}
.UsuarioNovoDadosContainer input {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(186, 186, 186, 0.8705882353);
  max-width: -moz-fit-content;
  max-width: fit-content;
  outline: none;
}
.UsuarioNovoDadosContainer input:hover {
  border-bottom: 1px solid #324354;
}
.UsuarioNovoDadosContainer input:focus {
  border-bottom: 1px solid #324354;
  border-color: #324354;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosIDContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosIDContainer #InputId {
  margin: 1em 0em 0em 0em;
  border: none;
  width: 40px;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosNomeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosNomeContainer #UsuarioNovoDadosNomeContainerEdit {
  display: flex;
  flex-direction: row;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosNomeContainer #InputNome {
  margin: 1em 0em 0em 0em;
  min-width: 600px;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosStatusContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1em 3em 2em 0em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosBasicInfoContainer .UsuarioNovoDadosStatusContainer #InputStatus {
  margin: 1em 0em 0em 0em;
  width: 40px;
}
.UsuarioNovoDadosContainer #infoIconNovoUsuario {
  color: rgb(255, 182, 72);
  margin-left: -2.5em;
  margin-right: 1em;
  cursor: pointer;
}
.UsuarioNovoDadosContainer #infoIconNovoUsuario #infoIcon {
  font-size: 18px;
  margin-bottom: -10px;
}
.UsuarioNovoDadosContainer #checkIconNovoUsuario {
  color: rgb(74, 222, 61);
  margin-left: -2.5em;
  margin-right: 1em;
  cursor: default;
}
.UsuarioNovoDadosContainer #checkIconNovoUsuario #checkIcon {
  font-size: 20px;
  margin-bottom: -10px;
}
.UsuarioNovoDadosContainer .PermissaoDivDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: -5px;
  font-weight: bold;
  opacity: 0.5;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer {
  display: flex;
  flex-direction: column;
  width: -moz-min-content;
  width: min-content;
  margin: 1em 0em 0em 2.5em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .SenhaInfoContainer {
  display: flex;
  flex-direction: row;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .SenhaInfoContainer #EditIconSenha:hover {
  color: #324354;
  cursor: pointer;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .InputSenhaEditarSenha {
  margin: 1em 0em 0em 0em;
  width: -moz-min-content;
  width: min-content;
  cursor: pointer;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .InputSenhaEditarSenha:disabled {
  cursor: default;
  outline: none;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .InputSenhaEditarSenha:hover {
  border-bottom: 1px solid #324354;
  transition: 0.4s;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .InputSenhaEditarSenha:hover:disabled {
  cursor: default;
  outline: none;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosSenhaContainer .InputSenhaEditarSenha:focus {
  outline: none;
  border-bottom: 1px solid #324354;
  transition: 0.4s ease-out;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer {
  display: flex;
  flex-direction: column;
  width: -moz-min-content;
  width: min-content;
  margin: 1em 0em 1em 7em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .SenhaInfoContainer {
  display: flex;
  flex-direction: row;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .SenhaInfoContainer #EditIconSenha:hover {
  color: #324354;
  cursor: pointer;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .InputSenhaEditarSenha {
  margin: 1em 0em 0em 0em;
  width: -moz-min-content;
  width: min-content;
  cursor: pointer;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .InputSenhaEditarSenha:disabled {
  cursor: default;
  outline: none;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .InputSenhaEditarSenha:hover {
  border-bottom: 1px solid #324354;
  transition: 0.4s;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .InputSenhaEditarSenha:hover:disabled {
  cursor: default;
  outline: none;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosConfirmarSenhaContainer .InputSenhaEditarSenha:focus {
  outline: none;
  border-bottom: 1px solid #324354;
  transition: 0.4s ease-out;
}
.UsuarioNovoDadosContainer #TextPermissoesUsuarioNovoDados {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;
}
.UsuarioNovoDadosContainer .UsuarioNovoDadosButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
}
.UsuarioNovoDadosContainer .linkBtn {
  text-decoration: none;
}/*# sourceMappingURL=UsuariosNovoDados.css.map */