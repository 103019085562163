@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  font-family: "Poppins", arial;
  background-color: #fafafa;
  margin: 0;
}
body #HomeAppContainer {
  font-family: "Poppins", arial;
  display: flex;
  float: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
body #HomeAppContainer a {
  text-decoration: none;
}
body #HomeAppContainer Link {
  text-decoration: none;
}
body #HomeAppContainer #headerAppHome {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #3080ac;
  color: #fafafa;
  cursor: default;
}
body #HomeAppContainer #headerAppHome p {
  font-weight: 500;
  font-size: 24px;
}
body #HomeAppContainer section.section-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding-top: 2em;
}
body #HomeAppContainer section.section-container .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1.2em 1.5em 1.2em;
  margin: 4em 2em 0em 2em;
  width: 16em;
  min-width: 16em;
  height: 25em;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  border-radius: 1em;
  color: #333333;
  border: 3px solid rgba(177, 216, 236, 0.4862745098);
}
body #HomeAppContainer section.section-container .card p {
  opacity: 0.8;
  font-size: 14px;
  text-align: justify;
  margin-top: -1em;
  margin-bottom: 2em;
}
body #HomeAppContainer section.section-container .card.two {
  border: 3px solid rgba(189, 189, 189, 0.4862745098);
}
body #HomeAppContainer section.section-container .card.two h1 {
  color: #898989;
}
body #HomeAppContainer section.section-container .card.two p {
  color: #898989;
}
body #HomeAppContainer section.section-container .card.three {
  border: 3px solid rgba(189, 189, 189, 0.4862745098);
}
body #HomeAppContainer section.section-container .card.three h1 {
  color: #898989;
}
body #HomeAppContainer section.section-container .card.three p {
  color: #898989;
}
body #HomeAppContainer section.section-container .card:hover {
  margin-top: 3.1em;
  border: 3px solid rgba(93, 182, 230, 0.8901960784);
  transition: ease-out 0.3s;
  box-shadow: 0 4px 8px 0 rgba(113, 161, 250, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
body #HomeAppContainer section.section-container .card:hover.one {
  position: static;
}
body #HomeAppContainer section.section-container .card:hover.two {
  position: static;
}
body #HomeAppContainer section.section-container .card:hover.three {
  position: static;
}
body #HomeAppContainer section.section-container .card.three:hover {
  border: 3px solid rgba(189, 189, 189, 0.4862745098);
  cursor: default;
}
body #HomeAppContainer section.section-container .card.two:hover {
  border: 3px solid rgba(189, 189, 189, 0.4862745098);
  cursor: default;
}
@media (max-width: 1088px) {
  body #HomeAppContainer .header {
    width: 100vw;
  }
  body #HomeAppContainer section.section-container .card {
    margin: 4em 1em 0em 1em;
  }
}
@media (max-width: 998px) {
  body #HomeAppContainer section.section-container {
    flex-wrap: wrap;
    margin-bottom: 2em;
  }
  body #HomeAppContainer section.section-container .card {
    width: 12em;
    max-width: 12em;
    min-width: 12em;
    height: 20em;
    margin: 3em 0.5em 0em 0.5em;
  }
}
@media (max-width: 868px) {
  body #HomeAppContainer section.section-container {
    margin-top: -2em;
    flex-wrap: wrap;
    margin-bottom: 2em;
  }
  body #HomeAppContainer section.section-container .card {
    width: 18em;
    max-width: 18em;
    min-width: 18em;
    height: 15em;
    margin: 3em 2em 0em 2em;
  }
}
@media (max-width: 700px) {
  body #HomeAppContainer section.section-container {
    margin-top: -2em;
    margin-bottom: 2em;
  }
  body #HomeAppContainer section.section-container .card {
    width: 18em;
    max-width: 18em;
    min-width: 18em;
    height: 15em;
    margin: 3em 0.5em 0em 0.5em;
  }
}
@media (max-width: 630px) {
  body #HomeAppContainer section.section-container {
    margin-top: -2em;
    margin-bottom: 2em;
  }
  body #HomeAppContainer section.section-container .card {
    width: 15em;
    max-width: 15em;
    min-width: 15em;
    height: 15em;
    margin: 3em 0.5em 0em 0.5em;
  }
}
@media (max-width: 328px) {
  body #HomeAppContainer section.section-container {
    flex-wrap: wrap;
    padding-top: 0em;
    margin-bottom: 2em;
  }
  body #HomeAppContainer section.section-container .card {
    width: 12em;
    max-width: 12em;
    height: 18em;
  }
}/*# sourceMappingURL=home.css.map */