.UsuariosTabelaDashContainer {
    display: flex;
    flex-direction: column;
    background-color: yellow;
    font-size: 12px;
    width: 70vw;
    height: 72vh;
    background-color:white;
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 

    .UsuariosDadosContainer {
        display: flex;
        flex: 1;
    }
    
    .UsuariosTabelaDashBarraPesquisa {
        display: flex;
        background-color: #324354;
        color: white;
        padding: 0.5em;
        height: 28px;
        justify-content: center;
        align-items: center;

        input {
            border-radius: 10px;
            width: 40%;
            height: 20px;
            margin-left: 1em;
            border-width: 0px;
            padding: 2px 10px;
        }
    }

    .UsuariosTabelaDashTable{
        display: flex;
        justify-content: center;
        // min-height: 30em;
        max-height: 40em;
        overflow-y: scroll;
        cursor: default;
        
        table {
            display: table;
            width: 90%;

            thead tr, th{
                padding: 8px 2px 8px 10px;
                color: #333333a1;
                text-align: left;
                font-size: 14px;
            }

            thead th {
                position: sticky;
                top: 0;
                background-color: rgb(255, 255, 255)
            }
    
            tbody {
                overflow: scroll;
            }
            tbody tr td{
                padding: 8px 2px 8px 10px;
                max-height: 10px;
            }

            tbody tr {
                max-height: 10px;
            }

            tbody tr:hover {
                background-color: #ececec;
            }
        }

        #ContainerIconSearchLoadingStatus {
            display: flex;
            justify-content: center;
            align-items: center;   
            flex-direction: column;   
            opacity: 0.8;   

            img#IdIconSearchLoadingStatus {
                width: 130px;
                height: 130px;
            }
        }

        #TabelaPessoasDashRow:hover {
            background-color: #ececec;
        }

        #UsuariosTabelaDashRow:hover {
            background-color: #ececec;
        }

        #IconEditProfessor {
            cursor: pointer;
            color: #324354;
            margin: 2px;
        }
    }

    .btnCriarUsuarioContainer{
        position: initial;
        display: flex;
        flex: 1;
        min-height: 4em;
        align-items: flex-end;
        padding-bottom: 2em;

        #btnCriarUsuario {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #33ab3f;
            color: white;
            border: 1px solid #33ab3f;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
            padding: 8px 12px;
            height: fit-content;
            margin-left: 2em;
            box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 

            #personAddIcon{
                margin-right: 8px;
            }
        }
    
        #btnCriarUsuario:hover {
            background-color: #07ce1b;
            border: 1px solid #07ce1b;
            cursor: pointer;
        }
    }

    #QtdUsuariosTabelaUsuarios {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        margin-right: 2em;
        padding-right: 1em;
        color: #324354;
        opacity: 0.7;
    }
}