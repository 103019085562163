@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    font-family: 'Poppins', arial;
    background-color: #fafafa;
    margin: 0;
    padding: 0;


    div.container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;

        header {
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: #3080ac;
            width: 100%;
            height: 4em;
            color: #fefefe;
            padding: 0em 0em 0em 2em;

            .menu {
                display: flex;
                width: 30vw;
                float: right;
                justify-content: space-between;
                align-items: center;
                padding: 0em 0em 0em 20vw;
                letter-spacing: .1em;
            }

            .button-sair {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                margin: 0em 0em 0em 30vw;

                p {
                    margin-right: 8px;
                }

            }
        }

        .title {
            display: flex;
            flex-direction: column;
            margin: .5em 0em .5em 5em;

            p.title-page {
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 0px;
                color: #2d78a0;
            }

            p.subtitle-page {
                margin-top: 0px;
                color: #333333;
                opacity: 0.8;
            }
        }

        section {
            width: 100%;

            .info-bar {
                display: flex;
                width: 100%;
                justify-content: initial;
                align-items: center;
                padding-right: 2em;
            }

        }

        .conteudo {
            min-width: 100%;
        }


        div.container-informativo {
            display: none;
            flex-direction: column;
            margin: 0em 0em 0em 5em;
            background-color: #e4e4e4;
            width: 25em;
            height: 5vh;
            padding: 1em;
            border-radius: .5em;
            margin-left: 100vh;
            align-items: flex-end;
            padding-bottom: 1.8em;

            p.informativo-title {

                font-size: 12px;
                letter-spacing: .1em;
                font-weight: bold;

                color: #916f00;
                margin: 0em 0em -.5em 0em;
            }

            p.informativo-desc {
                font-size: 11px;
                color: #333333;
                opacity: 0.7;
                text-align: justify;
            }
        }

        div.container-form {
            display: flex;
            flex-direction: column;
            margin: 2em 60em 0em 6em;
            min-width: 45em;
            font-weight: bold;
            color: #333333;
            font-size: 14px;

            label {
                color: #333333;
            }

            #observacao {
                color: #755020;
                opacity: 0.8;
                font-weight: normal;
                margin-bottom: -0.5em;
                margin-top: 1em;
            }


            #conteudo {
                margin-top: 0;
                min-width: 43em;
                max-width: 43em;
                margin-right: 0.5em;
            }

            #conteudoContainer {
                display: flex;
                flex-direction: row;
                margin: 0;
                padding: 0;
            }

            #atividade_aula {
                min-width: 43.3em;
                margin-right: 0.5em;
            }

            #atividade_casa {
                min-width: 43.3em;
                margin-right: 0.5em;
            }

            #colar {
                margin: 0;
                background: url('../../../images/edit-white.svg') no-repeat 4px;
                background-size: 20px;
                background-color: #2d78a0;
                color: white;
                width: 2em;
                height: 1.9em;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 2px;
                padding-right: -1px;
            }

            #colar:hover {
                background-color: #3080ac;
            }

            input {
                margin-top: .4em;
                margin-bottom: 1em;
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: .3em;
            }

            select {
                margin-top: .2em;
                margin-bottom: .8em;
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: .3em;
                max-width: 43em;
            }

            select:focus {
                border: 1px solid #3797ca;
                max-width: 43em;
            }

            select#turma {
                width: 5em;
            }

            input[type="text"] {
                padding: .3em;
                border: 1px solid rgba(0, 0, 0, 0.2);
            }

            input[type="text"]:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3aafeea8;
            }

            input[type="text"]::placeholder {
                color: #487892a8;
            }

            input[type="date"] {
                width: 10em;
            }

            input[type="date"]:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3081aca8;
            }

            input[type="number"] {
                width: 3em;
                padding: .3em;
            }

            input[type="number"]:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3081aca8;
            }

            input[type="submit"] {
                margin-bottom: 7em;
                width: auto;
                padding: .7em;
                border-radius: 5px;
                font-weight: bold;
                font-size: 14px;
                color: white;
            }

            #confirmar {
                background-color: #2d78a0;
            }

            #nomeAluno {
                min-width: max-content;
                padding-right: 0.8em;
            }

            #confirmar:hover {
                background-color: #3293c7;
            }

            #confirmar:disabled {
                background-color: #5c7988;
                cursor: wait;
            }

            #confirmar:disabled:hover {
                background-color: #5c7988;
                cursor: wait;
            }

            #exportar {
                display: none;
            }

            #exportarTodos {
                display: none;
            }

            input[type="submit"]:hover {
                background-color: #3080ac;
                cursor: pointer;
            }

            #boxNotas {
                width: 2em;
                font-size: 16px;
                margin: 2px;
                padding: 0;
            }

            .aulasContainer {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;

                .aulaCheckContainer {
                    display: flex;
                    align-items: center;
                    padding-bottom: 7px;

                    .editarCheckText {
                        margin: 0 0 0 3px;
                        padding: 0;
                        color: #313131;
                        font-weight: normal;
                        opacity: 0.8;
                    }

                    #aulasEdit {
                        margin: 0 0 0 1.5em;
                        size: 25px;
                    }
                }
            }



            #deletar {
                background-color: #bd3434;
                cursor: pointer;
            }

            #deletar:hover {
                background-color: #ce3434;
            }

            #deletar:disabled {
                cursor: default;
                background-color: #5c7988;
                cursor: default;
            }

            textarea {
                border: 1px solid rgba(0, 0, 0, 0.2);
                resize: none;
                margin-bottom: 1em;
                max-width: 41.5em;
            }

            textarea:focus {
                border: 1px solid #3081aca8;
                outline: 1px solid #3aafeea8;
            }

            textarea::placeholder {
                color: #487892c5;
            }

            details {
                margin: 0em 0em 2em 0em;
                min-width: max-content;

                summary {
                    border-radius: .3em;
                    padding: .3em 0em .3em .8em;
                    background-color: #2d78a0;
                    color: white;
                    outline: none;
                    margin-bottom: -1px;
                    margin-top: 1em;
                    cursor: pointer;
                    width: auto;

                }

                summary:hover {
                    background-color: #3080ac;
                }

                table {
                    border-bottom: 2px solid #2d78a0;
                    border-right: 2px solid #2d78a0;
                    border-left: 2px solid #2d78a0;
                    border-spacing: 0px;
                    width: 100%;


                    tr,
                    td {
                        border: 1px solid #adadad8a;
                        padding-left: .5em;

                        .RegistroFaltas {
                            color: #888888;
                            opacity: 0.8;
                            text-align: center;
                            font-weight: normal;
                        }
                    }

                    tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                    th {
                        border-bottom: 1px solid #adadad8a;
                        border-right: 1px solid #adadad8a;
                        border-left: 1px solid #adadad8a;
                        padding: .5em .3em .5em .3em;
                        cursor: default;
                    }

                    th[title]:hover::after {
                        background-color: #2d78a0;
                        color: white;
                    }

                    td.checkbox {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-self: center;
                        padding: 2px 5px;
                    }

                    tr td {
                        text-align: left;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }

            .loadingLançar {
                background-image: url('../../../images/Rolling-1s-200px.svg');
                background-repeat: no-repeat;
                //background-position: 2px 3px;
                background-size: contain;
                padding-right: .5em;
            }

        }

    }

}

@media (max-width: 769px) {

    body div.container {

        .title {
            margin: 0.1em 0em 1.5em 1em;
        }

        div.container-form #boxNotas {
            font-size: 22px;
            margin: 0;
        }

        section .info-bar {
            width: auto;
            padding-right: 0em;
        }

        div.container-form {
            margin: 0;
            padding: 0em 1em 0em 1em;
            min-width: initial;


            table {

                tr {
                    th {
                        font-weight: normal;
                        color: rgba(0, 0, 0, 0.5);
                    }

                    td {
                        padding: 1em 0em;
                    }
                }

                /* Tira coluna titulo matricula */
                th:nth-child(1) {
                    display: none;
                }

                /* Tira coluna dados matricula */
                td:nth-child(1) {
                    display: none;
                }

                /* Edita tamanho Nome matricula */
                th:nth-child(2) {
                    max-width: 8em;
                }

                /* Edita tamanho Nome matricula */
                td:nth-child(2) {
                    max-width: 8em;
                }

                tr {
                    td:nth-child(3) {
                        margin: 0;
                        text-align: justify;
                    }
                    
                    th:nth-child(4) {
                        visibility: hidden;                        
                    }

                    th:nth-child(4)::before {
                        visibility: visible;
                        content: '';
                        border: 1px solid #313131;
                    }

                    th:nth-child(6) {
                        visibility: hidden;                        
                    }

                    th:nth-child(6):before {
                        visibility: visible;
                        content: '';
                    }

                    td:nth-child(3) {
                        text-align: center;
                    }

                    td:nth-child(5) {
                        text-align: center;
                    }

                    th:nth-child(4) {
                        text-overflow: ellipsis !important;
                        overflow: hidden; 
                        white-space: nowrap;
                        padding: 0.5em 1em;
                        max-width: 2em;
                        margin: 0;
                        padding: 0;
                    }

                    

                    th:nth-child(6) {
                        text-overflow: ellipsis !important;
                        overflow: hidden; 
                        white-space: nowrap;
                        padding: 0.5em 1em;
                        max-width: 2em;
                        margin: 0;
                        padding: 0;
                    }

                }

            }

        }

    }
}