body {
    margin: 0;
    padding: 0;

    #HomePageGeneralContainer{
        display: flex;
        flex: 1;
        flex-direction: row;
        height: 100vh;
        width: 100vw;

        #HomePageContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
    
            .HomePageCardsContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                opacity: 0;
            }
        }
    }
}