.CardDashConteinerGreen {
    background-color: rgba(40,227,80,1);
    width: 200px;
    height: 100px;
    border-radius: 15px;
    background: rgba(40,227,80,1);
    background: linear-gradient(90deg, rgba(39,168,68,1) 0%, rgba(40,227,80,1) 100%);
    margin: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205,205,205,0.63); 
    cursor: default;

    .CardDashInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        color: white;

        #NumberCardDash {
            font-size: 52px;
        } 
        
        #GroupIcon {
            font-size: 70px;
            color: white;
            opacity: 0.5;
        }
    }

    #DescriptionDashCard{
        color: white;
        opacity: 0.9;
        margin-left: 1.6em;
        margin-bottom: 1em;
    }
    
}
