.TabelaPessoasDashContainer {
    font-size: 12px;
    width: 70vw;
    height: 65vh;
    background-color: white;
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);

    .TabelaPessoasDashBarraPesquisa {
        display: flex;
        background-color: #324354;
        color: white;
        padding: 0.5em;
        height: 28px;
        justify-content: center;
        align-items: center;

        input {
            border-radius: 10px;
            width: 40%;
            height: 20px;
            margin-left: 1em;
            border-width: 0px;
            padding: 3px 10px;
            outline: none;
        }
    }

    .TabelaPessoasDashTable {
        display: flex;
        flex: 1;
        justify-content: center;
        max-height: 95%;
        cursor: default;

        #TabelaPessoasDashRow {
            display: flex;
            flex: 1;

            //Matricula
            div:nth-child(1) {
                display: flex;
                flex: 4;
                justify-content: flex-start;
                align-items: flex-start;
                max-width: 5.8em;
                padding: 0px 10px;
            }

            //Nome
            div:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                min-width: 23em;
                flex: 1;
                padding: 0 5px;
            }

            //Curso
            div:nth-child(3) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex: 2;
                padding: 0px 5px;
            }

            //Turma
            div:nth-child(4) {
                display: flex;
                max-width: 4em;
                justify-content: center;
                align-items: center;
                flex: 3;
                padding: 0px 5px;
            }
        }

        #TabelaPessoasDashRow:hover {
            background-color: #ececec;
        }

        #tablePessoas {
            display: flex;
            flex: 1;
            padding: 0px 2em;
            width: 90%;
            display: flex;
            flex-direction: column;
            margin-bottom: 3em;

            #headerPessoas {
                div {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 0px;
                    margin-top: 2px;
                    margin-bottom: 4px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #333;
                    opacity: 0.8;

                    #headerItemPessoas {
                        display: flex;
                        flex: 1;
                        border-right: 1px solid white;
                    }

                    //Matricula
                    div:nth-child(1) {
                        display: flex;
                        flex: 4;
                        justify-content: flex-start;
                        align-items: flex-start;
                        max-width: 5.8em;
                        padding: 5px 10px;
                    }
    
                    //Nome
                    div:nth-child(2) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        min-width: 23em;
                        flex: 1;
                        padding-left: 5px;
                    }
                    
                    //Curso
                    div:nth-child(3) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex: 2;
                        padding: 5px 5px;
                    }
    
                    //Turma
                    div:nth-child(4) {
                        display: flex;
                        max-width: 4em;
                        justify-content: center;
                        align-items: center;
                        flex: 3;
                        padding: 5px 5px;
                        margin-right: 15px;
                    }
                }
            }

            #bodyItemPessoas {
                display: flex;
                flex: 1;
                flex-direction: column;
                margin: 0.8em 0em;
                

                div {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: space-around;

                    #bodyItemPessoas {
                        display: flex;
                        flex: 1;
                        // min-width: fit-content;
                        border: 1px solid yellow;
                    }

                    //Matricula
                    div:nth-child(1) {
                        display: flex;
                        flex: 4;
                        justify-content: flex-start;
                        align-items: flex-start;
                        max-width: 5.8em;
                        padding: 0px 10px;
                    }
    
                    //Nome
                    div:nth-child(2) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        min-width: 23em;
                        flex: 1;
                        padding-left: 5px;
                    }
                    
                    //Curso
                    div:nth-child(3) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex: 2;
                        padding: 0px 5px;
                    }
    
                    //Turma
                    div:nth-child(4) {
                        display: flex;
                        max-width: 4em;
                        justify-content: center;
                        align-items: center;
                        flex: 3;
                        padding: 0px 5px;
                    }
                }
            }

        }

        #QtdAlunosTabelaPessoas {
            display: flex;
            justify-content: flex-end;
            color: #324354;
            font-size: 12px;
            opacity: 0.6;
            margin-top: -8px;
        }

        #bodyPessoas {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-bottom: 2em;
            overflow-y: scroll;
            
        }

        #ContainerIconSearchLoadingStatus {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            opacity: 0.8;

            img#IdIconSearchLoadingStatus {
                width: 130px;
                height: 130px;
            }
        }

    }
}