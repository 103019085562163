

#containerExtrasModalCalcularAux {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1em 1.5em;
}

#TitleTextExtrasModalCalcularAux {
    text-align: center;
    color: #313131;
}

select#ExtrasModalCalcularAux {
    padding: 0.6em 0.5em;
    font-size: large;
    border-radius: 10px;
    color: #324354;
    border: 1px solid rgba(117, 117, 117, 0.63);
    margin-bottom: 1em;
    cursor: pointer;
    transition: 0.2s ease-in;
}

select#ExtrasModalCalcularAux>option {
    font-size: larger;
    color: #324354;
}

select#ExtrasModalCalcularAux:hover {
    color: #313131;
}

select#ExtrasModalCalcularAux > option:nth-child(even) {
    background-color: #32435419;
}

select#ExtrasModalCalcularAux > option#ExtrasModalFirstOptionSelecionar {
    color: #31313174;
}


#HomePageGeneralContainer {

    .ExtrasPainelContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: content;
        align-self: center;
        justify-self: center;
        justify-content: space-around;
        align-items: center;
        height: 30em;
        width: 80%;
        max-width: 80em;
        padding: 0.2em 1em;
        margin-bottom: 5em;
        border-radius: 20px;
        border-top: 40px solid #324354;
        background-color: white;
        box-shadow: 0px 2px 13px -10px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);


        .CardRelatorios {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 11em;
            height: 13em;
            border-radius: 10px;
            border: 4px solid #32435431;
            text-decoration: none;
            color: #324354;
            font-weight: bold;
            text-align: center;

            div {
                opacity: 0.7;
            }

            .IconGerenciarPainel {
                font-size: 80px;
                opacity: 0.7;
                color: #324354;
            }

            #textDescriptionCardExtras {
                width: min-content;
                text-align: center;
                height: 2em;
            }
        }

        .CardRelatorios:hover {
            cursor: pointer;
            border: 4px solid #324354db;
            box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
            transition: 0.5s;

            .IconGerenciarPainel {
                transition: 0.5s;
                opacity: 1;
            }

            div {
                opacity: 1;
                transition: 0.5s;
            }
        }
    }

    #ExtrasTitlePage {
        display: flex;
        font-size: 10px;
        color: #534A3C;
        font-weight: bold;
        opacity: 0.6;
        letter-spacing: 5px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}