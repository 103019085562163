#formBasic {
  display: flex;
  flex: 1;
  max-height: 13em;
  justify-content: center;
  flex-direction: column;
}
#formBasic #containerFormBasic {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#formBasic #containerFormBasic label.disabledLabel {
  opacity: 0.3;
}
#formBasic #containerFormBasic #cursoContainerFormBasic {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1em;
}
#formBasic #containerFormBasic .containerFormFirstLine {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
#formBasic #containerFormBasic .containerFormFirstLine #curso {
  display: flex;
  flex: 2;
  min-width: 100%;
}
#formBasic #containerFormBasic .containerFormFirstLine #turma {
  display: flex;
  flex: 1;
  min-width: 6em;
}
#formBasic #containerFormBasic .containerFormFirstLine #alunos {
  display: none;
}
#formBasic #containerFormBasic label {
  display: flex;
  flex: 1;
  color: #313131;
  opacity: 0.8;
  font-size: small;
  text-transform: uppercase;
  align-self: flex-start;
  justify-self: flex-start;
}
#formBasic #containerFormBasic select {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0.8em;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin-bottom: 1em;
  margin-top: 0.2em;
  color: rgba(49, 49, 49, 0.8352941176);
}
#formBasic #containerFormBasic select:hover {
  cursor: pointer;
}
#formBasic #containerFormBasic select:disabled {
  cursor: not-allowed;
}
#formBasic #containerFormBasic #FiltrarEmBrancoContainer {
  display: flex;
  flex: 2;
  width: 100%;
  color: rgba(49, 49, 49, 0.5);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
#formBasic #containerFormBasic #FiltrarEmBrancoContainer div:hover {
  cursor: pointer;
  color: rgb(49, 49, 49);
  transition: 0.3s;
}
#formBasic #containerFormBasic #FiltrarEmBrancoContainer input {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 0.2em;
}
#formBasic #containerFormBasic #CheckOptionsContainer {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
}
#formBasic #containerFormBasic #Avaliacao {
  margin-top: 0.8em;
}
#formBasic #containerFormBasic #FiltrarCheckAvaliacaoContainer {
  display: flex;
  flex: 1;
  width: 100%;
  color: rgba(49, 49, 49, 0.5);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
#formBasic #containerFormBasic #FiltrarCheckAvaliacaoContainer div:hover {
  cursor: pointer;
  color: rgb(49, 49, 49);
  transition: 0.3s;
}
#formBasic #containerFormBasic #FiltrarCheckAvaliacaoContainer input {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 0.2em;
}
#formBasic *:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}/*# sourceMappingURL=FormBasic.css.map */