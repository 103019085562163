.ProfessorDadosContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 4em;
    font-size: 12px;
    width: 70vw;
    height: 72vh;
    background-color: white;
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
    cursor: default;

    .ProfessorDadosInfoContainerGeral {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 130px;
    }

    .ProfessorDadosDashBarraPesquisa {
        display: flex;
        flex: 1;
        background-color: #324354;
        color: white;
        padding: 0.5em;
        min-height: 28px;
        max-height: 28px;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
    }

    .TextDescricao {
        font-weight: bold;
        color: #3333339c;
    }

    input {
        background-color: white;
        border: none;
        border-bottom: 1px solid #bababade;
        max-width: fit-content;
    }

    .ProfessorDadosBasicInfoContainer {
        display: flex;
        flex: 1;
        justify-content: space-between;
        max-height: 70px;


        .ProfessorDadosIDContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #InputId {
                background-color: #31313131;
                border-radius: 10px;
                padding: 2px 8px;
                text-align: center;
                margin-right: -10px;
                color: #313131ba;
                margin: 1em 0em 0em 0em;
                border: none;
                width: 40px;
            }
        }

        .ProfessorDadosNomeContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #InputNome {
                margin: 1em 0em 0em 0em;
                min-width: 600px;
            }
        }

        .ProfessorDadosStatusContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 3em 2em 0em;

            #InputStatus {
                margin: 1em 0em 0em 0em;
                width: 40px;
            }
        }

    }

    .ProfessorDadosSenhaContainer {
        display: flex;
        flex-direction: column;
        width: min-content;
        margin: 1em 0em 0em 2.5em;

        .SenhaInfoContainer {
            display: flex;
            flex-direction: row;

            #EditIconSenha:hover {
                color: #324354;
                cursor: pointer;
            }
        }



        .InputSenhaEditarSenha {
            margin: 1em 0em 0em 0em;
            width: min-content;
            cursor: pointer;
        }

        .InputSenhaEditarSenha:disabled {
            cursor: default;
            outline: none;
        }


        .InputSenhaEditarSenha:hover {
            border-bottom: 1px solid #324354;
        }

        .InputSenhaEditarSenha:hover:disabled {
            cursor: default;
            outline: none;
        }

        .InputSenhaEditarSenha:focus {
            outline: none;
        }

    }

    .disabledBtnEdit {
        cursor: crosshair;
    }

    #TextPermissoesProfessorDados {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin: 0.5em 0em;
        max-height: 20px;
    }

    .ProfessorDadosBodyContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding-bottom: 1.2em;
    }

    .ProfessorDadosButtonsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: -1.5em;
        text-decoration: none;

        #linkButtonBack {
            text-decoration: none;
        }
    }
}