#formBasic {
    display: flex;
    flex: 1;
    // padding: 1em 8em 0em 8em;
    // margin-bottom: -4em;
    // margin-top: -1em;
    max-height: 13em;
    justify-content: center;
    flex-direction: column;

    

    #containerFormBasic {
        display: flex;
        flex: 1;
        flex-direction: column;
        // max-width: 25em;
        justify-content: center;
        align-items: center;

        label.disabledLabel {
            opacity: 0.3;
        }

        #cursoContainerFormBasic {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-right: 1em;
        }

        .containerFormFirstLine {
            display: flex;
            flex: 1;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;

            #curso {
                display: flex;
                flex: 2;
                min-width: 100%;
            }

            #turma{
                display: flex;
                flex: 1;
                min-width: 6em;
            }

            #alunos {
                display: none;
            }
        }

        label {
            display: flex;
            flex: 1;
            color: #313131;
            opacity: 0.8;
            font-size: small;
            text-transform: uppercase;
            align-self: flex-start;
            justify-self: flex-start;
        }

        select {
            display: flex;
            flex: 1;
            width: 100%;
            padding: 0.8em;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.8);
            margin-bottom: 1em;
            margin-top: 0.2em;
            color: #313131d5;
        }

        select:hover {
            cursor: pointer;
        }

        select:disabled{
            cursor: not-allowed;
        }

        #FiltrarEmBrancoContainer {
            display: flex;
            flex: 2;
            width: 100%;
            color: rgba(49, 49, 49, 0.5);
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            div:hover {
                cursor: pointer;
                color: rgba(49, 49, 49, 1);
                transition: 0.3s;
            }

            input {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-right: 0.2em;
            }
        }

        #CheckOptionsContainer {
            display: flex;
            flex: 1;
            width: 100%;
            justify-content: space-between;
        }

        #Avaliacao {
            margin-top: 0.8em;
        }

        #FiltrarCheckAvaliacaoContainer {
            display: flex;
            flex: 1;
            width: 100%;
            color: rgba(49, 49, 49, 0.5);
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            div:hover {
                cursor: pointer;
                color: rgba(49, 49, 49, 1);
                transition: 0.3s;
            }

            input {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-right: 0.2em;
            }
        }

    }

    *:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
}