#ContainerDark {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  font-size: 12px;
}
#ContainerDark .ButtonDark-Main {
  margin: -6em 0em 0px 20%;
  cursor: pointer;
}

.modal-overlay {
  background-color: rgba(226, 226, 226, 0.6);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-content-Button-Dark {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 70vh;
  text-transform: uppercase;
  cursor: default;
}
.modal-content-Button-Dark .modalHeaderButtonDark {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal-content-Button-Dark .modalHeaderButtonDark h2 {
  text-align: center;
  color: #313131;
  opacity: 0.9;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-content-Button-Dark .modalHeaderButtonDark hr {
  opacity: 0.8;
  width: 90%;
  margin-bottom: 2em;
}
.modal-content-Button-Dark .modalHeaderButtonDark p {
  font-size: 14px;
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin: -12px 0px 40px 0px;
}
.modal-content-Button-Dark #ButtonDarkContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modal-content-Button-Dark #ButtonDarkContainer .ButtonDarkClose {
  background-color: #c54040;
  border: 1px solid #c54040;
}
.modal-content-Button-Dark #ButtonDarkContainer .ButtonDarkClose:hover {
  background-color: #eb4444;
  border: 1px solid #eb4444;
}
.modal-content-Button-Dark #ButtonDarkContainer .Button-Dark-Disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #646464;
  border: 1px solid #646464;
  width: -moz-max-content;
  width: max-content;
  min-width: 100px;
  height: -moz-max-content;
  height: max-content;
  margin: 0em 2em 0em 2em;
  padding: 0.5em 0.8em;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: wait;
}
.modal-content-Button-Dark #ButtonDarkContainer .Button-Dark-Disabled #LoadingButonDarkIcon {
  width: 24px;
  height: 24px;
  size: 24px;
  color: white;
}

#ButtonDark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #324354;
  border: 1px solid #324354;
  width: -moz-max-content;
  width: max-content;
  min-width: 100px;
  height: -moz-max-content;
  height: max-content;
  margin: 0em 2em 0em 2em;
  padding: 0.5em 0.8em;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
  cursor: pointer;
}
#ButtonDark #CloseIconDark {
  padding-right: 5px;
  font-size: 20px;
  margin: 0;
}

#ButtonDark:hover {
  background-color: #345577;
  border: 1px solid #345577;
}

button:hover {
  background-color: #3d5368;
  cursor: pointer;
  border: 1px solid #3d5368;
  transition: 0.2s;
  text-decoration: none;
}/*# sourceMappingURL=ButtonDark.css.map */