@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  font-family: "Poppins", arial;
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}
body .loadingBtnContainer {
  opacity: 0.8;
  cursor: wait;
  transition: 0.5s;
}
body .containerAdminPainel {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body .containerAdminPainel #headerLoginAdmin {
  background-color: #368EBD;
  background: linear-gradient(0deg, #2d4e6d 0%, #236488 100%);
  min-height: 25vh;
  width: 100%;
  margin: 0px;
  color: white;
}
body .containerAdminPainel #headerLoginAdmin .header-infodat-label {
  color: white;
  margin: -1vh 0em 0em 2vw;
}
body .containerAdminPainel .icon-svg {
  margin-top: -2em;
}
body .containerAdminPainel .loading {
  position: absolute;
}
body .containerAdminPainel #PainelAdministrativoLink {
  display: flex;
  color: #3080ac;
  width: 85%;
  padding-left: 5px;
  margin: 12px 0px 4px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: normal;
  display: none;
}
body .containerAdminPainel #PainelAdministrativoLink a:hover {
  color: #3693c5;
  text-decoration: none;
}
body .containerAdminPainel .professor-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1em;
  margin-top: -7vh;
  min-width: 18em;
  max-width: 19em;
}
body .containerAdminPainel .professor-form-card h1 {
  color: #333333;
}
body .containerAdminPainel .professor-form-card select {
  color: #333333;
  margin-top: 10px;
  width: 85%;
  font-size: 16px;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
  text-transform: capitalize;
}
body .containerAdminPainel .professor-form-card select option {
  padding: 10px;
  margin: 5px;
}
body .containerAdminPainel .professor-form-card select #escolaOption {
  color: #bababa;
}
body .containerAdminPainel .professor-form-card select #escolaOption:focus {
  color: red;
}
body .containerAdminPainel .professor-form-card #usuarios {
  margin-bottom: 15px;
  margin-top: 25px;
}
body .containerAdminPainel .professor-form-card #PainelAdministrativoLink {
  display: flex;
  color: #3080ac;
  width: 85%;
  padding-left: 5px;
  margin: -28px 0px 25px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: normal;
}
body .containerAdminPainel .professor-form-card #PainelAdministrativoLink a:hover {
  color: #3693c5;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.5s;
}
body .containerAdminPainel .professor-form-card select:focus {
  outline: none !important;
  border: 1px solid #368EBD;
  box-shadow: 0 0 10px rgba(170, 231, 255, 0.4588235294);
  transition: 0.3s;
}
body .containerAdminPainel .professor-form-card input.password {
  width: 75%;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px 15px;
  border-radius: 8px;
  margin-top: -15px;
  margin-bottom: 15px;
}
body .containerAdminPainel .professor-form-card input.password:focus {
  outline: none !important;
  border: 1px solid #3080ac;
  box-shadow: 0 0 10px rgba(170, 228, 255, 0.6039215686);
  border-radius: 8px;
  color: #1b85be;
  transition: 0.3s;
}
body .containerAdminPainel .professor-form-card input.buttonEnter {
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  background-color: #33ab3f;
  margin-bottom: 5px;
  box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-width: 0px;
  border-radius: 0.5em;
  cursor: pointer;
}
body .containerAdminPainel .professor-form-card input.buttonEnter:hover {
  background-color: #41c24e;
  transition: 0.3s ease;
}
body .containerAdminPainel .professor-form-card input.buttonEnter:focus {
  outline: none !important;
}
@media (max-width: 320px) {
  body .container .professor-form-card {
    padding: 2.5em 1em;
    width: 85vw;
    margin-right: -0.7em;
  }
}/*# sourceMappingURL=Login.css.map */