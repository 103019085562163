.modal-content-notas-aux {
    display: flex;
    flex-direction: column;
    min-width: 40em;
    max-width: 60em;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
    max-height: 70vh;
    text-transform: uppercase;
    cursor: default;

    h2 {
        text-align: center;
        color: #313131;
        opacity: 0.9;
        max-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        font-size: 14px;
        text-align: center;
        color: #313131;
        opacity: 0.7;
        max-height: 40px;
        margin: -12px 0px 23px 0px; 
    }

    #ButtonModalStudentContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;   
    }

    #ButtonConfirmDash {
        padding: 0.8em 1.5em;
        margin-right: 1.6em;
    }
}

.NotasDadosContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 70vw;
    height: 72vh;
    background-color: white;
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 13px -7px rgba(117, 117, 117, 0.63), 7px 15px 16px -6px rgba(205, 205, 205, 0.63);
    cursor: default;

    .ReactModal__Content {
        background-color: white;
    }

    .ReactModal__Content--after-open {
        background-color: white;
    }

    

    .NotasDadosDashContainer {
        display: flex;
    }

    #buttonAuxNotasAdmin {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background-color: #324354;
        color: white;
        border: 1px solid #324354;
        border-radius: 5px;
        padding: 0.6em 2em;
        max-width: 9em;
        margin-top: 5px;
    }

    .NotasDadosDashBarraPesquisa {
        display: flex;
        background-color: #324354;
        color: white;
        padding: 0.5em;
        height: 28px;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
    }

    .TextDescricao {
        font-weight: bold;
        color: #3333339c;
    }

    input {
        background-color: white;
        border: none;
        border-bottom: 1px solid #bababade;
        max-width: fit-content;
        outline: none;
    }

    input:hover {
        border-bottom: 1px solid #324354;
    }

    input:focus {
        border-bottom: 1px solid #324354;
        border-color: #324354;
    }

    .NotasDadosBasicInfoContainer {
        display: flex;
        flex: 1;
        justify-content: space-between;
        max-height: 70px;

        .NotasDadosIDContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #InputCodigo {
                background-color: #31313131;
                border-radius: 10px;
                padding: 2px 10px;
                text-align: center;
                color: #313131ba;
                margin: 1em 0em 0em 0em;
                margin-right: -15px;
                border: none;
                width: 90px;
            }
        }

        .NotasDadosCursoContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 0em 0em 2.5em;

            #NotasDadosCursoContainerEdit {
                display: flex;
                flex-direction: row;
            }

            #InputCurso {
                display: flex;
                flex: 1;
                margin: 1em 0em 0em 0em;
                min-width: 500px;
            }
        }

        .NotasDadosTurmaContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 3em 2em 0em;

            #InputTurma {
                margin: 1em 0em 0em 0em;
                width: 40px;
            }
        }
    }

    .NotasDadosBasicInfoContainerRow2 {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        max-height: 80px;

        .NotasDadosDisciplinaContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 5em 0em 2.5em;

            #InputDisciplina {
                
                margin: 1em 0em 0em 0em;
                margin-right: -15px;
                width: fit-content;
            }
        }

        .NotasDadosAvaliacaoContainer {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin: 1em 3em 2em 0em;

            #InputAvaliacao {
                margin: 1em 0em 0em 0em;
                width: 100px;
            }
        }
    }

    .PermissaoDivDescription {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        margin-bottom: -5px;
        font-weight: bold;
        opacity: 0.5;
    }

    .NotasDadosSenhaContainer {
        display: flex;
        flex-direction: column;
        width: min-content;
        margin: 1em 0em 0em 2.5em;

        .SenhaInfoContainer {
            display: flex;
            flex-direction: row;

            #EditIconSenha:hover {
                color: #324354;
                cursor: pointer;
            }
        }



        .InputSenhaEditarSenha {
            margin: 1em 0em 0em 0em;
            width: min-content;
            cursor: pointer;
        }

        .InputSenhaEditarSenha:disabled {
            cursor: default;
            outline: none;
        }


        .InputSenhaEditarSenha:hover {
            border-bottom: 1px solid #324354;
            transition: 0.4s;
        }

        .InputSenhaEditarSenha:hover:disabled {
            cursor: default;
            outline: none;
        }

        .InputSenhaEditarSenha:focus {
            outline: none;
            border-bottom: 1px solid #324354;
            transition: 0.4s ease-out;
        }

    }

    .disabledBtnEdit {
        cursor: crosshair;
    }

    #TextPermissoesNotasDados {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin: 1em 0em;
    }

    .NotasDadosButtonsContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        margin-left: 2em;
        margin-right: 3em;
        justify-content: space-between;
        align-items: center;

    }

    .linkBtn {
        text-decoration: none;
    }
}

#notasDadosTableContainer {
    display: flex;
    justify-content: center;
    // overflow-x: scroll;
    table {
        display: block;
        overflow: scroll;
        max-height: 20em;
        border-collapse: collapse;
        border: 1px solid rgba(50, 66, 83, 0.5);
        margin: 0;
        padding: 0;

        td {
            padding: 0.2em 0.7em;
        }
        thead {
            position: sticky;
            top: 0;
            font-weight: bold;
            text-align: center;
            background-color: rgba(50, 66, 83, 1);
            color: white;
            margin: 0;
            padding: 0;
        }

        tbody {
            font-size: 14px;
            tr:nth-child(even){
                background-color: rgba(122, 146, 171, 0.1);
            }
            
        }
    }
}