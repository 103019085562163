a {
    cursor: pointer;
    text-decoration: none;
    color: #2a72ad;
}

a:hover {
    text-decoration: underline;
}

#HeaderDashContainer {
    background-color: #EBEBEC;
    display: flex;
    flex: 1;
    max-height: 50px;
    width: auto;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: #313131;
    opacity: 0.9;
    padding: 0em 5em;
    box-shadow: 0px 3px 3px -3px rgba(139, 139, 139, 0.63), 3px 3px 16px -3px rgba(216, 216, 216, 0.63); 

    .HeaderDashContato {
        display: flex;
        flex-direction: row;
        align-items: center;

        #HeaderDashContatoText {
            margin-right: 10px;
        }

        #HeaderDashContatoIcon {
            color: rgba(39,168,68,1);
        }
    }

    .HeaderDashUser {
        display: flex;
        flex-direction: row;
        align-items: center;

        #HeaderDashUserIcon {
            margin-right: 5px;
        }
    }
}